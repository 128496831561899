import React from "react"
import {
  Text,
  Button,
  Stack,
  Wrap,
  Spinner,
} from "@chakra-ui/react"
import { useGlobalContext } from "../DataManagement/GlobalContextManager"
import { Navigate, useNavigate } from "react-router-dom"
import { CatalogEntryV2 } from "./CatalogEntryV2"
import { BasePageTemplate } from "./BasePageTemplate"
import { CartV2LineItem, ErrorTypes } from "../DataManagement/Types"
import { ErrorScreen } from "../CommonComponents/ErrorScreen"
import { useCartManagerV2 } from "../DataManagement/CartManagerV2"
import { FloatingCartBar } from "./FloatingCartBar"


export const CartV2BuilderView: React.FC<{}> = () => {

  // Tap into contexts and hooks
  const globalContext = useGlobalContext();
  const cartManagerV2 = useCartManagerV2();
  const navigate = useNavigate();


  const everythingLoaded = !globalContext.isLoading && !cartManagerV2.isLoading


  // Called by children product carts upon add to cart clicked
  const addToCart = (item: CartV2LineItem) => {
    const editResponse = cartManagerV2.addToCart(item)
    console.log(editResponse)

    return editResponse
  }


  if (globalContext.isError) {
    // Global context error
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Build Your Cart"}>
        <ErrorScreen error={({ type: ErrorTypes.GlobalContextError, message: "" })} />
      </BasePageTemplate>
    )
  } else if (cartManagerV2.isError) {
    // Cart manager error
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Build Your Cart"}>
        <ErrorScreen error={({ type: ErrorTypes.CartManagerError, message: cartManagerV2.statusMsg || "" })} />
      </BasePageTemplate>
    )
  } else if (globalContext.subscriptionId) {
    // Raffle sub ID exists = Move to raffling screen
    return <Navigate to="/raffle" />
  } else if (globalContext.selectedCollectionHandle === "") {
    // No collection specified to load
    return <Navigate to="/" />
  } else if (!globalContext.isLoading && !globalContext.selectedCollection) {
    // global context has loaded, yet no collection has been selected
    // Deadlock, must nav out
    return <Navigate to="/" />
  } else if (!everythingLoaded || !globalContext.selectedCollection) {
    // Things still loading
    // Display a loading screen
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Build Your Cart"}>
        <Spinner />
        <Text mt={2} mb={8}>Loading...</Text>
      </BasePageTemplate>
    )
  } else if (globalContext.collectionTimeState === "CLOSED") {
    // Collection has closed (GB ended)
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Build Your Cart"}>
        <Text>The sale has ended.</Text>
        <Button onClick={() => navigate("/")} colorScheme="whiteAlpha">Main Menu</Button>
      </BasePageTemplate>
    )
  } else if (globalContext.collectionTimeState === "BEFORE_LAUNCH") {
    // Collection isn't even open!
    // User shouldn't be here
    return <Navigate to="/" />
  } else if (globalContext.selectedCollection.totalHypemainQty <= 0) {
    // Hype main product has sold out
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Build Your Cart"}>
        <Text>The product has sold out.</Text>
        <Button onClick={() => navigate("/")} colorScheme="whiteAlpha">Main Menu</Button>
      </BasePageTemplate>
    )
  } else {
    // Conditions just right to display
    return (
      <>
        <BasePageTemplate maxW="container.lg" headerText={"Build Your Cart"}>
          {/* <Text mt={2} mb={8}>Available Items</Text> */}
          <Stack direction="column" spacing="24px" justify="center" pb={16}>
            {globalContext.selectedCollection!.catalog.map(item => (
              <CatalogEntryV2 item={item} addToCart={addToCart} key={`catalog-item-${item.productId}-${item.variantId}`} />
            ))}
          </Stack>

          {/* Spacer so there's a gap under the last item */}
        </BasePageTemplate>

        {/* Floating cart area */}
        <FloatingCartBar buttonArea={
          <Wrap direction={["row"]} align="center" justify="center">
            <Button onClick={() => navigate("/")}>Back to Main Menu</Button>
            <Button
              colorScheme="ck"
              onClick={() => navigate("/cart/checkout")}
              disabled={cartManagerV2.totalItems <= 0}
            >
              Review Cart
            </Button>
          </Wrap>

        }
          displayGbStatus={!globalContext.inStockModeSwitch}
        />

      </>
    )
  }
}
