import React, { useState } from "react"
import {
  Box,
  Text,
  Button,
  Center,
  Flex,
  Stack,
  Wrap,
  AspectRatio,
  Image,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spacer,
} from "@chakra-ui/react"
import { Select } from "chakra-react-select"
import { useGlobalContext } from "../DataManagement/GlobalContextManager"
import { CartEditFnReturnType, CartV2LineItem, CatalogProductType, ChoiceType, ListedOptionType, ProductTypes, SelectedOptionTrackerType, UnsplitVariantType } from "../DataManagement/Types"
import { WarningTwoIcon } from "@chakra-ui/icons"
import { ReactComponent as LogoWhite } from '../../assets/LogoWhite-NoText.svg'


export const CatalogEntryV2: React.FC<{
  item: CatalogProductType,
  addToCart: (item: CartV2LineItem) => CartEditFnReturnType
  // addToCart: (item: InCartProductType) => void
}> = ({
  item,
  addToCart
}) => {

    // Tap into contexts
    const globalContext = useGlobalContext()


    // Look up the quantity limit for this item from the selected collection (via global context)
    // Can be null/undef if there's simply no limit for this product
    let productQtyLimit = globalContext.selectedCollection?.qtyLimits[item.productId] || null


    // State for add to cart errors
    const [addToCartErrors, setAddToCartErrors] = useState<string[]>([])

    // State for errors in display altogether
    const [errorMsg, setErrorMsg] = useState<string | null>(null)

    // Manage selected quantity
    const [selectedQuantity, setSelectedQuantity] = useState<number>(1)

    // If un-split variants, manage which variant gets selected in the dropdown
    const [selectedUnsplitVariant, setSelectedUnsplitVariant] = useState<UnsplitVariantType | null>(null)


    // Get sold out status
    let qtyInStock = 0
    if (item.productType === ProductTypes.UnsplitVariants) {
      if (selectedUnsplitVariant) {
        qtyInStock = selectedUnsplitVariant.qtyInStock
      } else {
        qtyInStock = item.unsplitVariants.reduce((a, b) => a + b.qtyInStock, 0);
      }
    } else {
      qtyInStock = item.qtyInStock!
    }

    // Set max selectable quantity for the numeric selector
    // LONG-TERM: The default upper limit is 999 - surely we're not selling a product that needs more than 999 per user, right?
    // Change if necessary
    const maxSelectableQty = Math.min(qtyInStock, (productQtyLimit || 999))


    // Manage selected options

    // Build an easily reference-able catalog of options
    const optionsCatalog = item.backendOptions

    // Build the selected options index from the given options
    const initialOptionsState: SelectedOptionTrackerType[] = item.backendOptions.map(option => {
      return {
        displayName: option.displayName,
        systemId: option.systemId,
        selectedChoice: null
      }
    })

    // Initialize the selected options type based on this
    const [selectedOptions, setSelectedOptions] = useState<SelectedOptionTrackerType[]>(initialOptionsState)


    // Expose an easy way to select an option
    const selectOption = (optionId: number, choice: ChoiceType | null) => {

      // Duplicate the selected options array
      // let tempSelectedOptions = selectedOptions.slice();
      // NOTE TO SELF: This does NOT clone the objects inside as a deep clone
      let tempSelectedOptions = selectedOptions.map(a => ({ ...a }))

      // Find the option in the selected options state
      const specifiedOptionIndex = tempSelectedOptions.findIndex(option => option.systemId === optionId)
      if (specifiedOptionIndex === -1) {
        // This should never happen - given option does not exist in the list of options for this product
        console.error("Option ID given for selecting an option did not exist in the selected options array")
        setErrorMsg("Option selected for nonexistent option ID")
        return
      }

      // Set the choice for the selected option and set that as new state
      tempSelectedOptions[specifiedOptionIndex].selectedChoice = choice
      setSelectedOptions(tempSelectedOptions)
      console.log(`Setting choice for option ${optionId} to ${choice?.displayName || "null"}`)
      console.log(`Selected options:`)
      console.log(selectedOptions)

      // Also clear add to cart warnigns just as expected interaction behavior
      setAddToCartErrors([])
    }


    // Sanity checker for selected options on add-to-cart
    const verifySelectedOptions: () => { success: boolean, errorMsg: string[] } = () => {

      let errors: string[] = []

      // First, if the product type is UNSPLIT_VARIANTS, a variant should be selected
      if (item.productType === ProductTypes.UnsplitVariants && selectedUnsplitVariant === null) {
        errors.push("Please select a variant.")
      }

      // Second, all options for the product should be selected
      for (const option of selectedOptions) {
        if (option.selectedChoice === null) {
          errors.push(`Please select a choice for the option ${option.displayName}.`)
        }
      }

      // Third, check quantity (this shouldn't really be violated unless something's wrong with the stepper)
      if (selectedQuantity <= 0 || selectedQuantity > maxSelectableQty) {
        errors.push("Please select a valid quantity for this product.")
      }

      // All sanity checks pass
      return {
        success: errors.length > 0 ? false : true,
        errorMsg: errors
      }

    }


    // Behavior for add to cart pressed
    const onAddToCart = () => {

      // If verify selected options fails, set error msgs and return
      const verifyResults = verifySelectedOptions()
      if (!verifyResults.success) {
        console.log("Errors during add to cart")
        console.log(verifyResults.errorMsg)
        setAddToCartErrors(verifyResults.errorMsg)
        return
      }

      // Otherwise clear errors, transform data, and call add to cart
      setAddToCartErrors([])

      // Generate variant ID properly
      let variantId: string = item.variantId || selectedUnsplitVariant!.variantId

      let productToAdd: CartV2LineItem = {
        variant_id: variantId,
        quantity: selectedQuantity,
        product_id: item.productId,
        // price: price,
        options: selectedOptions.map(option => {
          return {
            option_id: option.systemId,
            choice_id: option.selectedChoice?.systemId!,
            upgrade_variant_id: option.selectedChoice?.addedCostVariantId || null
          }
        })
      }

      const addToCartResult = addToCart(productToAdd)

      // If add to cart results in errors, reflect that in state
      if (!addToCartResult.success) {
        setAddToCartErrors([addToCartResult.status])
      }
      addToCart(productToAdd)
    }

    // Find the highest cost option in a list of options
    const findHighestOptionCost = (option: ListedOptionType) => {
      return option.choices.reduce<number>((highestCostVariant, currOptionChoice) : number => {
        if (currOptionChoice.addedCostVariantId){
          const upgradeVariantCost = globalContext.selectedCollection?.upgradeVariants[currOptionChoice.addedCostVariantId]
          if(!!!upgradeVariantCost || highestCostVariant > upgradeVariantCost){
            return highestCostVariant
          } 
          return upgradeVariantCost
        }
        return highestCostVariant
      }, 0)
    }

    // Check if product has upgrade SKUs, reflect that in price display
    // Skip if all options are chosen (just calculate final cost in that case)
    const allOptionsChosen = selectedOptions.map(selectedOption => selectedOption.selectedChoice).every(choice => choice !== null)
    let highestCostUpgradeVariantSeen = 0
    let exactAddedCost = 0
    if (!allOptionsChosen) {
      highestCostUpgradeVariantSeen = item.backendOptions.map(option => findHighestOptionCost(option)).reduce(
        (previousValue, currentValue) => previousValue + currentValue
      )

    } else {
      for (const option of selectedOptions) {
        if (option.selectedChoice?.addedCostVariantId) {
          const addedCost = globalContext.selectedCollection?.upgradeVariants[option.selectedChoice.addedCostVariantId]
          if (!addedCost) {
            // This should never happen
            setErrorMsg(`Added cost variant ${option.selectedChoice.addedCostVariantId} does not exist in table`)
          }
          exactAddedCost += addedCost!
        }
      }
    }



    // If unsplit variants, calculate what the max/min prices are for display string
    let priceString = ""
    if (item.productType === ProductTypes.UnsplitVariants) {

      if (selectedUnsplitVariant) {
        priceString = `$${selectedUnsplitVariant.price.toFixed(2)}`
      } else {
        const unsplitPrices = item.unsplitVariants.map(variant => variant.price) || []
        if (!unsplitPrices || unsplitPrices.length <= 0) {
          // This should never happen - unsplit variant product with no unsplit variants
          // Guaranteed by shopify
          priceString = ""
          setErrorMsg("Product has no variants despite being unsplit variant type")
        }
        const unsplitMinPrice = Math.min.apply(null, unsplitPrices)
        const unsplitMaxPrice = Math.max.apply(null, unsplitPrices)
        if (unsplitMinPrice === unsplitMaxPrice) {
          priceString = `$${(unsplitMinPrice + exactAddedCost).toFixed(2)}`
        } else {
          priceString = `$${unsplitMinPrice.toFixed(2)} - $${(unsplitMaxPrice + highestCostUpgradeVariantSeen).toFixed(2)}`
        }
      }

    } else if (selectedOptions.map(selectedOption => selectedOption.selectedChoice).every(choice => choice !== null)) {
      // All options chosen
      priceString = `$${(item.price! + exactAddedCost).toFixed(2)}`
    } else if (highestCostUpgradeVariantSeen > 0) {
      // Price can vary depending on selected options
      priceString = `$${item.price!.toFixed(2)} - $${(item.price! + highestCostUpgradeVariantSeen).toFixed(2)}`
    } else {
      // Single price
      priceString = `$${(item.price! + exactAddedCost).toFixed(2)}`
    }


    // Render the product card
    return (
      <Box key={`product-${item.productId}-${item.variantId}`} bgColor="white" rounded="md" width="100%" textColor="black" minH="100%">
        <Stack direction={["column", "column", "row"]}>
          <AspectRatio ratio={[16 / 9, 16 / 9, 4 / 3]} w={["100%", "100%", "500px", "700px"]} >
            {item.imageSrc ?
              <Image src={item.imageSrc || ""} alt={item.variantTitle ? `${item.productTitle} - ${item.variantTitle}` : item.productTitle} objectFit='cover' roundedTopLeft="md" roundedBottomLeft={["0", "0", "md"]} roundedTopRight={["md", "md", "0"]} />
              :
              <Center w="100%" h="100%" bgColor="gray.500" roundedTopStart="md" roundedBottomStart={["none", "none", "md"]} roundedTopEnd={["md", "md", "none"]}>
                <LogoWhite width="min(8em, 25%)" />
              </Center>
            }
          </AspectRatio>
          <Flex direction={["column"]} align="start" px={4} pt={[0, 0, 4]} pb={4} textAlign="start" w="100%">
            <Box w="100%">

              {/* Product title */}
              <Text fontSize="2xl" fontWeight="semibold" textAlign="start">
                {item.variantTitle ? `${item.productTitle} - ${item.variantTitle}` : item.productTitle}
              </Text>

              {errorMsg ?
                <>
                  {/* Error section */}
                  <WarningTwoIcon boxSize="2em" />
                  <Text fontSize="lg" textAlign="start" fontWeight="bold">Error</Text>
                  <Text>Please report the following to CannonKeys:</Text>

                  {errorMsg}
                </>
                :
                <>
                  {/* Price section */}
                  <Text fontSize="lg" textAlign="start">{priceString}</Text>

                  {/* Options dropdowns */}
                  <Stack direction="column" my={1} mt={2}>

                    {/* Unsplit variants if applicable */}
                    {item.productType === ProductTypes.UnsplitVariants &&
                      <Box w="100%">
                        <Select
                          colorScheme="ck"
                          isClearable={true}
                          isSearchable={false}
                          options={item.unsplitVariants.map(variant => {
                            return (
                              {
                                value: variant,
                                label: variant.displayName
                              }
                            )
                          })}
                          placeholder="Variant"
                          onChange={selection => {
                            if (selection) {
                              setSelectedUnsplitVariant(selection.value)
                            } else {
                              setSelectedUnsplitVariant(null)
                            }
                          }}
                        />
                      </Box>
                    }

                    {/* Backend options */}
                    {optionsCatalog.map(option => {

                      return (
                        <Box w="100%" key={`backend-option-${option.systemId}`}>
                          <Select
                            colorScheme="ck"
                            isClearable={true}
                            isSearchable={false}
                            options={option.choices.map(choice => ({ label: choice.displayName, value: choice }))}
                            placeholder={option.displayName}
                            onChange={selection => {
                              if (selection) {
                                selectOption(option.systemId, selection.value)
                              } else {
                                selectOption(option.systemId, null)
                              }
                            }}
                          />
                        </Box>
                      )
                    })}
                  </Stack>
                </>
              }

            </Box>
            <Spacer />

            {/* Warnings if any */}
            {
              addToCartErrors.length > 0 &&
              <Stack direction="column">
                {addToCartErrors.map(error => (
                  <Text key={`error-${error}`} align="start" fontWeight="600" textColor="red">{error}</Text>
                ))}
              </Stack>
            }

            <Wrap mt={4} overflow="visible">
              {(!productQtyLimit || productQtyLimit < 1) &&
                <NumberInput
                  w="20"
                  defaultValue={qtyInStock <= 0 ? 0 : 1}
                  min={qtyInStock <= 0 ? 0 : 1}
                  max={qtyInStock <= 0 ? 0 : maxSelectableQty}
                  onChange={(_, number) => setSelectedQuantity(number)}
                  isDisabled={qtyInStock <= 0 || !!errorMsg}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              }
              {
                qtyInStock > 0 ?
                  <Button colorScheme="ck" w="auto" mt={3} onClick={() => onAddToCart()} isDisabled={!!errorMsg}>
                    Add to Cart
                  </Button>
                  :
                  <Button colorScheme="ck" w="auto" mt={3} disabled>
                    Sold Out
                  </Button>
              }

            </Wrap>
          </Flex>
        </Stack>
      </Box >
    )

  }
