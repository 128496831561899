import React, { useEffect } from "react"
import { useGlobalContext } from "../DataManagement/GlobalContextManager"
import { useNavigate } from "react-router-dom"
import { useCartManagerV2 } from "../DataManagement/CartManagerV2"

export const ResetView: React.FC<{}> = () => {

  const globalContext = useGlobalContext()
  const cartManagerV2 = useCartManagerV2()
  const navigate = useNavigate()

  // Clear all built carts, local storages, etc
  useEffect(() => {
    if (cartManagerV2.cart.length > 0) {
      cartManagerV2.clearCart()
    }
    if (globalContext.selectedCollectionHandle !== "") {
      globalContext.setCollection("")
    }
    if (globalContext.subscriptionId) {
      globalContext.setSubscriptionId(null)
    }
    navigate("/")
  }, [cartManagerV2, globalContext])

  return null
}