import React, { } from "react"
import {
  Box,
  Stack,
  Text,
  VStack,
  Image,
  AspectRatio,
  Container,
  Divider,
} from "@chakra-ui/react"
import { useCartManagerV2 } from "../DataManagement/CartManagerV2"
import { CartV2LineItem, UnsplitVariantType } from "../DataManagement/Types"
import { useGlobalContext } from "../DataManagement/GlobalContextManager"
import { findMatchingCatalogItemByVariantId } from "../DataManagement/CartManagerV2Utils"
import { ReactComponent as LogoWhite } from '../../assets/LogoWhite-NoText.svg'


export const CartDisplayRow: React.FC<{
  item: CartV2LineItem
}> = ({
  item
}) => {

    const globalContext = useGlobalContext()

    const catalogItem = findMatchingCatalogItemByVariantId(item.variant_id, globalContext.selectedCollection?.catalog!)
    if (!catalogItem) {
      return (
        <Box py={2} px={[2, 4]}>
          <Stack direction="row">
            <AspectRatio ratio={4 / 3} w="100px" >

              <Box bgColor="gray.500" rounded="sm">
                <LogoWhite width="50%" />
              </Box>

            </AspectRatio>
            <Stack direction="column" flex={1} textAlign="start" textColor="black">
              <Text fontSize="lg" fontWeight="semibold">Error</Text>
              <Text>Could not find this product in the catalog to display.</Text>
              <Text>If the error persists, please contact CannonKeys.</Text>
              <Text>More info: Variant ID is {item.variant_id}</Text>
            </Stack>
          </Stack>

        </Box>
      )
    }
    
    let unsplitVariant: UnsplitVariantType | null = null
    if (catalogItem.productType === "UNSPLIT_VARIANTS") {
      unsplitVariant = catalogItem.unsplitVariants.find(variant => variant.variantId === item.variant_id)!
    }
    const optionsMapping = item.options.map(cartOption => {
      const backendOption = catalogItem.backendOptions.find(option => option.systemId === cartOption.option_id)!
      const backendChoice = backendOption.choices.find(choice => choice.systemId === cartOption.choice_id)!
      return {
        cartOption: cartOption,
        backendOption: backendOption,
        backendChoice: backendChoice
      }
    })



    return (
      <Box py={2} px={[2, 4]}>
        <Stack direction="row">
          <AspectRatio ratio={4 / 3} w="100px" >

            {catalogItem.imageSrcTransformed ?
              <Image src={catalogItem.imageSrcTransformed} alt={catalogItem.variantTitle ? `${catalogItem.productTitle} - ${catalogItem.variantTitle}` : catalogItem.productTitle} objectFit='cover' rounded="sm" />
              :
              <Box bgColor="gray.500" rounded="sm">
                <LogoWhite width="50%" />
              </Box>
            }
          </AspectRatio>
          <Stack direction="column" flex={1} textAlign="start" textColor="black">
            <Text fontSize="lg" fontWeight="semibold">{catalogItem.variantTitle ? `${catalogItem.productTitle} - ${catalogItem.variantTitle}` : catalogItem.productTitle}</Text>
            {unsplitVariant &&
              <Text>{unsplitVariant.displayName}</Text>
            }
            {optionsMapping.map(option => (
              <Text key={`catalogItemoption-${catalogItem.variantId}-${option.backendOption.displayName}`}>{option.backendOption.displayName}: {option.backendChoice.displayName}</Text>
            ))}
            <Text>Quantity: {item.quantity.toFixed(0)}</Text>

          </Stack>
        </Stack>

      </Box>
    )
  }


export const CartDisplay: React.FC<{}> = () => {

  // Get cart contents
  const cartManagerV2 = useCartManagerV2()

  return (
    <Container maxW="container.sm">
      <VStack bgColor="white" rounded="md" divider={<Divider />} align="start" py={2} mt={4} mb={2}>
        {cartManagerV2.cart.map(item => (
          <CartDisplayRow item={item} key={JSON.stringify(item)} />
        ))}
      </VStack>
    </Container>

  )
}