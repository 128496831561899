import React, { useState } from "react"
import { useGlobalContext } from "../DataManagement/GlobalContextManager"
import { BasePageTemplate } from "./BasePageTemplate"
import { Navigate, useNavigate } from "react-router-dom"

import { useCartManagerV2 } from "../DataManagement/CartManagerV2"
import { CartV2LineItem, CartV2SelectedOption } from "../DataManagement/Types"
import {
  Box,
  Button,
  Stack,
  useDisclosure,
  Wrap,
  Text,
  Container
} from "@chakra-ui/react"
import { InCartEntryV2 } from "../CartBuilderView/InCartEntryV2"
import { FloatingCartBar } from "../CartBuilderView/FloatingCartBar"
import { ConfirmEditModal } from "./ConfirmEditModal"
import { AddItemModal } from "./AddItemModal"

export const EditOrderModifyView: React.FC<{}> = () => {

  const globalContext = useGlobalContext()
  const cartManagerV2 = useCartManagerV2()
  const navigate = useNavigate()

  // Used for qty reference
  const [originalCart] = useState<CartV2LineItem[]>(cartManagerV2.cart)
  const { isOpen: isConfirmModalOpen, onOpen: onConfirmModalOpen, onClose: onConfirmModalClose } = useDisclosure();
  const { isOpen: isAddItemModalOpen, onOpen: onAddItemModalOpen, onClose: onAddItemModalClose } = useDisclosure();


  // If the order edit details aren't set, navigate out
  if (!globalContext.orderEditDetails.email || !globalContext.orderEditDetails.orderNum || !globalContext.orderEditDetails.subId) {
    return <Navigate to="/edit/lookup" />
  }

  const removeFromCart = (item: CartV2LineItem) => {
    const editResponse = cartManagerV2.setQuantityForItem(item, 0)
    console.log(editResponse)
  }

  const setCartQuantity: (item: CartV2LineItem, quantity: number) => void = (item, quantity) => {
    const editResponse = cartManagerV2.setQuantityForItem(item, quantity)
    console.log(editResponse)
  }

  const setOptions: (item: CartV2LineItem, option: CartV2SelectedOption) => void = (item, option) => {
    const ret = cartManagerV2.setChoiceForItem(item, option)
    console.log(ret)
  }

  const getOriginalCartQuantity = (variantId: string) => {
    return originalCart.find(item => item.variant_id === variantId)?.quantity || 0
  }

  return (
    <>
      <BasePageTemplate maxW="container.lg" headerText="Edit Order">
        <Container maxW="container.sm">
          <Text mb={2}>To remove items or change added-cost options, please contact customer support via the self-service portal.</Text>
          <Button mb={8} colorScheme="whiteAlpha" as="a" rel="noreferrer" target="_blank" href="https://self-service.cannonkeys.com/login">Self-Service Portal</Button>
        </Container>
        <Stack direction="column" spacing={6}>
          {cartManagerV2.cart.map(item => (
            <InCartEntryV2
              key={item.variant_id}
              cartItem={item}
              originalQuantity={getOriginalCartQuantity(item.variant_id)}
              setOptions={setOptions}
              setCartQuantity={setCartQuantity}
              removeFromCart={removeFromCart}
            />
          ))}
          <Box pb={6}>
            <Button onClick={onAddItemModalOpen} colorScheme="whiteAlpha" width="auto">
              Add Items
            </Button>
          </Box>


        </Stack>

      </BasePageTemplate>
      <FloatingCartBar
        buttonArea={
          <Wrap direction={["row"]} align="center" justify="center">
            <Button colorScheme="red" onClick={() => navigate("/edit/lookup")}>Cancel Edit</Button>
            <Button onClick={onAddItemModalOpen}>Add Items</Button>
            <Button colorScheme="ck" onClick={onConfirmModalOpen}>Confirm Changes</Button>
          </Wrap>

        }
        displayGbStatus={false}
      />
      <ConfirmEditModal
        isOpen={isConfirmModalOpen}
        onClose={onConfirmModalClose}
      />
      <AddItemModal
        isOpen={isAddItemModalOpen}
        onClose={onAddItemModalClose}
      />
    </>
  )
}