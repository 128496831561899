import React, { useEffect } from "react"
import {
  Text,
  VStack,
  Code,
  Heading,
  Wrap,
  Button,
  Box,
  Flex,
} from "@chakra-ui/react"
import { useQuery } from "react-query"
import { getReleases } from "../../api/backend"
import { BarLoader } from "react-spinners"
import { EditIcon, WarningTwoIcon } from "@chakra-ui/icons"
import { BasePageTemplate } from "./BasePageTemplate"
import { ReleaseCard, ReleaseCardTemplate } from "./ReleaseCard"
import { useGlobalContext } from "../DataManagement/GlobalContextManager"
import { Navigate, useNavigate } from "react-router-dom"
import { useCartManagerV2 } from "../DataManagement/CartManagerV2"
import axios from "axios"
import { ErrorScreen } from "../CommonComponents/ErrorScreen"
import { ErrorTypes } from "../DataManagement/Types"

export const LandingView: React.FC<{}> = () => {


  // Tap into context
  const globalContext = useGlobalContext()
  const cartManagerV2 = useCartManagerV2()
  const navigate = useNavigate()


  useEffect(() => {

    if (cartManagerV2.cart.length > 0) {
      cartManagerV2.clearCart()
    }
    if (globalContext.orderEditDetails.email || globalContext.orderEditDetails.orderNum || globalContext.orderEditDetails.subId) {
      globalContext.setOrderEditDetails({ subId: null, orderNum: null, email: null })
    }
    // if (globalContext.selectedCollectionHandle !== "") {
    //   globalContext.setCollection("")
    // }

  }, [cartManagerV2, globalContext])


  // Fetch the active releases
  const {
    isLoading: isLoadingReleases,
    isError: isErrorReleases,
    data: releasesData,
    error: releasesError
  } = useQuery('getReleases', () => getReleases(),
    {
      refetchInterval: 60 * 1000,
      retry: (failureCount, error) => {
        console.log(error)
        if (axios.isAxiosError(error)) {
          console.log(error)
          if (error.response?.status === 429) {
            return true
          }
        }
        return failureCount < 3
      }
    }
  )

  let releases = releasesData?.releases || [];


  // Function called from the child release cards when a user selects a release to go to its cart screen
  const onSelectRelease = (collectionHandle: string) => {
    // Set the active release in global context
    globalContext.setCollection(collectionHandle)

    // Clear cart contents (so products from one release don't persist into another)
    cartManagerV2.clearCart()

    // Transition to the cart page
    navigate("/cart")
  }

  // If contexts have errors, display them
  if (globalContext.isError) {
    return (
      <BasePageTemplate maxW="container.lg">
        <ErrorScreen error={({ type: ErrorTypes.GlobalContextError, message: "" })} />
      </BasePageTemplate>
    )
  } else if (cartManagerV2.isError) {
    return (
      <BasePageTemplate maxW="container.lg">
        <ErrorScreen error={({ type: ErrorTypes.CartManagerError, message: cartManagerV2.statusMsg || "" })} />
      </BasePageTemplate>
    )
  }
  // If the releases data has not finished loading, display a loading screeen
  else if (isLoadingReleases) {

    return (

      <BasePageTemplate maxW="container.sm">

        <Text pt={6}>Loading Catalog...</Text>

        <VStack w="100%" mt={6}>
          <BarLoader
            loading={true}
            color="white"
            height="5px"
            width="100%"
          />
        </VStack>

      </BasePageTemplate>

    )
  }

  // If subid is set, nav out
  else if (globalContext.subscriptionId) {
    return <Navigate to="/raffle" />
  }

  // If the fetch has failed, display an error
  else if (isErrorReleases) {

    let errorString = JSON.stringify(releasesError)
    if (axios.isAxiosError(releasesError)) {
      errorString = releasesError.message
    }

    return (

      <BasePageTemplate maxW="container.sm">
        <Flex direction="column" align="center">
          <WarningTwoIcon boxSize="5em" />
          <Heading mt={6} as="h3" fontSize="24px" fontWeight="500">Couldn't fetch catalog</Heading>
          <Text mt={4}>Please double-check your internet connection and try again later.</Text>
          <Text>If the issue persists, please contact CannonKeys with the details below.</Text>

          <Text mt={6}>Details:</Text>
          <Code>{errorString}</Code>
          <Button mt={16} onClick={() => navigate("/edit")} variant="outline">Edit Existing Order</Button>
        </Flex>
      </BasePageTemplate>

    )
  }

  else if (releases.length <= 0) {
    return (

      <BasePageTemplate maxW="container.sm" >
        <Text pt={6}>Nothing available right now.</Text>
        <Button mt={4} onClick={() => navigate("/edit")} colorScheme="whiteAlpha">Edit Existing Order</Button>
      </BasePageTemplate>

    )
  }

  else {
    return (

      <BasePageTemplate maxW="container.2xl">
        {/* <Text>Currently Live</Text> */}
        <Heading as="h3" fontWeight="500" mb={6} fontSize="1.75em" pt={2}>Currently Live</Heading>
        <Wrap spacing={10} justify="center" pb={6}>
          {releases.map(release => (
            <ReleaseCard release={release} key={release.collection_title} onSelectRelease={onSelectRelease} />
          ))}
          <ReleaseCardTemplate
            releaseName="Edit Order"
            aspectRatioContents={
              <Box bgColor="gray.500">
                <EditIcon boxSize="min(8em, 25%)" color="gray.300" />
              </Box>
            }
            secondTextLine={
              <Text>Edit an existing order</Text>
            }
            button={
              <Button colorScheme="ck" onClick={() => navigate("/edit")}>Edit</Button>
            }
          />
        </Wrap>
      </BasePageTemplate>

    )
  }

}
