import React, { useEffect, useState } from "react"
import { useCollectionQuery } from "./useCollection";
import { CollectionTimeStates, SelectedCollection } from "./Types";
import { clearStoredCollection, clearStoredSubId, getStoredCollection, getStoredSubId, setStoredCollection, setStoredSubId } from "./LocalStorageManager";
import { DateTime } from "luxon";


// ========================= //
// Global Context Manager Context


// The typedef for the order specified for editing
type OrderEditDetailsType = {
  email: string | null,
  orderNum: number | null,
  subId: string | null
}


// The global context contents

type GlobalContextType = {
  // The current selected collection's data
  selectedCollection: SelectedCollection | null,
  selectedCollectionHandle: string,

  // Whether or not the current collection is open time-wise
  collectionTimeState: CollectionTimeStates,

  // Whether or not 30 mins has passed and the raffle rush time is over
  inStockModeSwitch: boolean,

  // The current subscription ID
  subscriptionId: string | null,

  // The stored details for the order to edit
  orderEditDetails: OrderEditDetailsType,

  // Whether or not the cart/catalog is ready for use
  isLoading: boolean,
  isError: boolean,

  // A human-readable error in case something does go wrong
  errorMsg: string | null,

  // Set active collection and populate catalog data
  setCollection: (handle: string) => void,

  // Set subscription ID
  setSubscriptionId: (id: string | null) => void,

  // Set the stored details for the order to edit
  setOrderEditDetails: (input: OrderEditDetailsType) => void,
}


// The collection context and functions to use it
const GlobalContext = React.createContext<GlobalContextType>({
  selectedCollection: null,
  selectedCollectionHandle: "",
  collectionTimeState: null,
  inStockModeSwitch: false,

  subscriptionId: null,
  orderEditDetails: {email: null, orderNum: null, subId: null},

  isLoading: true,
  isError: false,

  errorMsg: null,

  setCollection: (_) => { },
  setSubscriptionId: (_) => { },
  setOrderEditDetails: (_) => { },
})

// Easier use of the context
export const useGlobalContext = () => { return React.useContext(GlobalContext) }


// ========================= //
// Global Context Manager itself


export const GlobalContextManager: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {

  // State
  const [collectionHandleInput, setCollectionHandleInput] = useState<string>(getStoredCollection() || "")
  const [subscriptionId, setSubscriptionId] = useState<string | null>(getStoredSubId() || null)
  const [orderEditDetails, setOrderEditDetails] = useState<OrderEditDetailsType>({email: null, orderNum: null, subId: null})

  // Queries
  const { selectedCollection, isLoading: isCollectionLoading, isError: isCollectionError, errorMsg: collectionErrorMsg } = useCollectionQuery(collectionHandleInput, undefined)

  // Timer to recalculate GB open status
  const releaseTime = selectedCollection?.releaseTime
  const raffleStartTime = selectedCollection?.raffleStartTime
  const closingTime = selectedCollection?.closingTime
  const [lastRefresh, setLastRefresh] = useState<DateTime>(DateTime.now());
  useEffect(() => {
    const interval = setInterval(() => {
      if (releaseTime && closingTime) {
        setLastRefresh(DateTime.now());
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [lastRefresh, releaseTime, closingTime]);

  let collectionTimeState: CollectionTimeStates = null
  if (releaseTime && closingTime && raffleStartTime) {
    const now = DateTime.now()
    if (now < releaseTime) {
      collectionTimeState = "BEFORE_LAUNCH"
    } else if (now < raffleStartTime) {
      collectionTimeState = "PENDING_RAFFLE"
    } else if (now < closingTime) {
      collectionTimeState = "RAFFLING"
    } else {
      collectionTimeState = "CLOSED"
    }
  }


  // Calculate if it's been over 45 mins from launch
  let inStockModeSwitch = false
  if (raffleStartTime) {
    // Returns positive minutes if raffle yet to start
    // Returns negative minutes if raffling has started
    const minsUntilRaffle = raffleStartTime.diffNow('minutes').as('minutes')
    if (minsUntilRaffle <= 45) {
      inStockModeSwitch = true
    }
  }


  // Set stored collection handle on change
  useEffect(() => {
    if (collectionHandleInput === "") {
      clearStoredCollection()
    } else {
      setStoredCollection(collectionHandleInput)
    }
  }, [collectionHandleInput])


  // Set sub ID on change
  useEffect(() => {
    if (!subscriptionId) {
      clearStoredSubId()
    } else {
      setStoredSubId(subscriptionId)
    }
  }, [subscriptionId])



  const contextValue: GlobalContextType = {
    selectedCollection: selectedCollection,
    selectedCollectionHandle: collectionHandleInput,
    collectionTimeState: collectionTimeState,
    inStockModeSwitch: inStockModeSwitch,

    subscriptionId: subscriptionId,

    orderEditDetails: orderEditDetails,

    isLoading: isCollectionLoading,
    isError: isCollectionError,

    errorMsg: collectionErrorMsg || null,

    setCollection: setCollectionHandleInput,
    setSubscriptionId: setSubscriptionId,
    setOrderEditDetails: setOrderEditDetails
  }

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  )

}
