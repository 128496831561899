import {
  BackendPostLineItem,
  BackendPostOptionsEntry,
  BackendPostSelectedOptions,
  CartConverterReturnType,
  CartV2LineItem,
  CartV2SelectedOption,
  CatalogProductType,
  ErrorType,
  ErrorTypes,
  ProductTypes,
  QuantityLimitLookupTable,
  UpgradeVariantLookupTable
} from "./Types"

// ========================= //
// Cart Management Utilities


// Utility to check type equivalence of two InCartProductTypes (variant ID and options are equivalent)
export const isEquivalentCartItem = (itemA: CartV2LineItem, itemB: CartV2LineItem) => {

  // Variant ID equivalence check
  if (itemA.variant_id !== itemB.variant_id) {
    console.log("Diff variant IDs")
    return false
  }

  // Options equivalence check
  // Does not check if the options themselves are equivalent (i.e. if B has more/diff options than A)
  // But they should be equivalent anyways 
  for (const optionA of itemA.options) {
    // Find a corresponding option in item B where the option and its selected choice are the same
    const correspondingOptionB = itemB.options.find(optionB => (optionB.option_id === optionA.option_id && optionB.choice_id === optionA.choice_id))
    if (!correspondingOptionB) {
      console.log(`Couldn't find same option for ${optionA.option_id.toString()} with choice ${optionA.choice_id.toString()}`)
      return false
    }
  }

  console.log("Found equivalent items")
  return true

}


// Utility to return the index and quantity of an item in cart if already exists, or null otherwise
export const findItemInCart: (cart: CartV2LineItem[], lookupItem: CartV2LineItem) => { index: number, quantity: number } | null = (cart, lookupItem) => {
  console.log("findItemInCart called")

  const index = cart.findIndex(cartItem => isEquivalentCartItem(cartItem, lookupItem))
  if (index === -1) {
    console.log(`findItemInCart: Couldn't find specified item, returning null`)
    return null
  } else {
    console.log(`findItemInCart: Returning index ${index} for lookup item`)
    return {
      index: index,
      quantity: cart[index].quantity
    }
  }

}


// Find the total quantity in cart for a specific product ID
export const totalQtyOfProductInCart = (cart: CartV2LineItem[], productId: string) => {
  let count = 0
  for (const cartItem of cart) {
    if (cartItem.product_id === productId) {
      count += cartItem.quantity
    }
  }
  return count
}


// Verify that any modifications to a product does not cause cart to exceed quantity limits
// Returns true if everything is fine, returns false if qty cap is exceeded
export const verifyQuantityCapForProduct = (cart: CartV2LineItem[], qtyLimits: QuantityLimitLookupTable, productId: string) => {
  console.log("Qty limits:")
  console.log(qtyLimits)

  const qtyLimitForProduct = qtyLimits[productId] || null
  if (!qtyLimitForProduct) {
    console.log("No qty limit found for given product")
    return true
  }
  console.log("Qty limit found for given product")
  const qtyInCartForProduct = totalQtyOfProductInCart(cart, productId)
  if (qtyInCartForProduct > qtyLimitForProduct) {
    return false
  }
  return true
}


// Find product from the catalog that matches that of the cart by variant id
export const findMatchingCatalogItemByVariantId = (variantId: string, catalog: CatalogProductType[]) => {
  return catalog.find(item => {
    if (item.productType !== "UNSPLIT_VARIANTS") {
      // If NOT unsplit variants, find variant ID match
      if (item.variantId === variantId) {
        return true
      }
      return false
    } else {
      // If unsplit variants, find variant ID match under unsplit variants
      for (const unsplitVariant of item.unsplitVariants) {
        if (unsplitVariant.variantId === variantId) {
          return true
        }
      }
      // If here, no match was found
      return false
    }
  })
}


// Find any product in cart exceeding available stock
export const findCartItemsExceedingStock: (cart: CartV2LineItem[], catalog: CatalogProductType[]) => {items: CartV2LineItem[], error: ErrorType | null} = (cart, catalog) => {
  // Check whether or not the items in cart have sold out
  let cartItemsExceedingAvailableStock: CartV2LineItem[] = []
  for (const cartItem of cart) {
    // Find the item that matches this item from the catalog
    const matchingProduct = findMatchingCatalogItemByVariantId(cartItem.variant_id, catalog)
    if (!matchingProduct) {
      // This should never happen - Cart contains items not in catalog
      // For this to be valid, cart should always be cleared before catalog
      return {
        items: [], 
        error: {type: ErrorTypes.CartManagerError, message: "Cart contains items that don't exist in the catalog"}
      }
    }

    // If product is of unsplit type, find the variant ID that matches and get the quantity
    if (matchingProduct.productType === ProductTypes.UnsplitVariants) {
      const matchingVariant = matchingProduct.unsplitVariants.find(variant => variant.variantId === cartItem.variant_id)
      if (!matchingVariant) {
        // This should never happen - Cart contains items not in catalog
        return {
          items: [], 
          error: {type: ErrorTypes.CartManagerError, message: "Cart contains unsplit variant item that don't exist in the catalog listing's variant options"}
        }
      }

      if (cartItem.quantity > matchingVariant.qtyInStock) {
        cartItemsExceedingAvailableStock.push(cartItem)
      }
    }

    // Otherwise if the item is of split variants or standalone type, simply compare qties 
    else {
      if (cartItem.quantity > matchingProduct.qtyInStock!) {
        cartItemsExceedingAvailableStock.push(cartItem)
      }
    }
  }
  return {
    items: cartItemsExceedingAvailableStock,
    error: null
  }
}


// Get total number of items in cart
export const getTotalItemQty = (cart: CartV2LineItem[]) => {
  const totalItems = cart.reduce(
    (previousValue, item) => previousValue + item.quantity,
    0
  );
  return totalItems
}


// Calculate total cost of cart
// Returns -1 if fails due to added cost variant in cart not being in added cost variants lookup table
// Returns -2 if price lookup fails (i.e. catalog doesn't contain price of variant ID)
export const getTotalCartPrice = (cart: CartV2LineItem[], upgradeVariants: UpgradeVariantLookupTable, catalog: CatalogProductType[]) => {
  let totalCartPrice = 0;
  for (const cartItem of cart) {
    const cartItemPrice = getPriceOfVariantId(cartItem.variant_id, catalog)
    if (!cartItemPrice) {
      return -2
    }
    totalCartPrice += cartItemPrice * cartItem.quantity

    // If an option specifies an added cost variant ID, need to add that as well
    for (const option of cartItem.options) {
      if (option.upgrade_variant_id) {
        const addedCostVariantCost = upgradeVariants[option.upgrade_variant_id]
        if (!addedCostVariantCost) {
          console.error("Added cost variant was specified but not present in added cost variants lookup table")
          return -1
        }
        totalCartPrice += addedCostVariantCost * cartItem.quantity
      }
    }
  }

  return totalCartPrice
}


// Get price of line item by variant ID
// Returns a number or null if variant ID not found
export const getPriceOfVariantId = (
  variantId: string,
  catalog: CatalogProductType[]
) => {
  // Locate matching entry
  const matchingCatalogEntry = findMatchingCatalogItemByVariantId(variantId, catalog)
  if (!matchingCatalogEntry) {
    return null
  }

  // If Unsplit Variants type, derive price from variants list
  // LONG-TERM: Replace productType with .length > 0 so productType and unsplitVariants mismatch cannot occur
  // LONG-TERM: To faciliate the above, add a isUnsplitVariants() which just checks length
  if (matchingCatalogEntry.unsplitVariants.length > 0) {
    const matchingVariantId = matchingCatalogEntry.unsplitVariants.find(item => item.variantId === variantId) 
    return matchingVariantId?.price || null
  } else {
    return matchingCatalogEntry.price! 
  }
} 


// Convert cart data to the form wanted by backend
export const convertCartToBackendForm: (cart: CartV2LineItem[]) => CartConverterReturnType = (cart) => {

  // Helpers to filter the cart items
  interface QuantityLookupTable {
    [variantId: string]: number;
  }

  interface OptionsLookupTable {
    [productId: string]: BackendPostSelectedOptions[];
  }

  const backendCartDataBuilder: QuantityLookupTable = {}
  const backendOptionsDataBuilder: OptionsLookupTable = {}

  for (const cartItem of cart) {

    // LONG-TERM: For now, don't support multiple same variant IDs as diff line items (i.e. with diff options)
    if (backendCartDataBuilder[cartItem.variant_id]) {
      return {
        cart: null,
        options: null,
        error: "Duplicate variant IDs"
      }
    }

    // Add variant ID
    backendCartDataBuilder[cartItem.variant_id] = cartItem.quantity

    // Register its selected options 
    // This should never occur anyways since addToCart denies this
    // LONG-TERM: As above
    if (backendOptionsDataBuilder[cartItem.product_id]) {
      return {
        cart: null,
        options: null,
        error: "Duplicate product ID with differing option sets"
      }
    }

    // Only add options if necessary (i.e. don't create [] option sets)
    if (cartItem.options.length > 0) {

      // Format the options for the product ID to empty array
      backendOptionsDataBuilder[cartItem.product_id] = []

      for (const option of cartItem.options) {

        // Add the option
        backendOptionsDataBuilder[cartItem.product_id].push({
          option_id: option.option_id,
          choice_id: option.choice_id
        })

        // If this involves an added cost SKU, add that to the cart line items
        if (option.upgrade_variant_id) {
          // If it's already in the cart, increment its quantity
          if (backendCartDataBuilder[option.upgrade_variant_id]) {
            backendCartDataBuilder[option.upgrade_variant_id] += cartItem.quantity
          }
          // Otherwise, just set directly
          else {
            backendCartDataBuilder[option.upgrade_variant_id] = cartItem.quantity
          }
        }
      }
    }
  }

  // At this point, we have a built set of cart and options data
  // Convert to the form desired and return
  const returnCart: BackendPostLineItem[] = Object.keys(backendCartDataBuilder).map((variantId, index) => {
    return {
      variant_id: variantId,
      quantity: backendCartDataBuilder[variantId]
    }
  })

  const returnOptions: BackendPostOptionsEntry[] = Object.keys(backendOptionsDataBuilder).map((productId, index) => {
    return {
      product_id: productId,
      selected_options: backendOptionsDataBuilder[productId].map(option => {
        return {
          option_id: option.option_id,
          choice_id: option.choice_id
        }
      })
    }
  })

  return {
    cart: { line_items: returnCart },
    options: { options: returnOptions },
    error: null
  }
}


// Convert cart data from the backend form to frontend form
export const convertCartToFrontendForm: (
  backendCart: BackendPostLineItem[],
  backendOptions: BackendPostOptionsEntry[],
  catalog: CatalogProductType[],
) => {
  frontendCart: CartV2LineItem[] | null,
  errorMsg: string | null
} = (
  backendCart,
  backendOptions,
  catalog,
) => {
    const tmpCart: CartV2LineItem[] = []
    const seenUpgradeVariantIds: string[] = []
    const missingBackendCartVariantIds: string[] = []
    // Loop through backend cart, matching each up to a catalog item and merging the data to a frontend cart line item
    for (const backendLineItem of backendCart) {
      
      // First find the catalog entry and build the line item
      const correspondingCatalogEntry = findMatchingCatalogItemByVariantId(backendLineItem.variant_id, catalog)
      if (!correspondingCatalogEntry) {
        missingBackendCartVariantIds.push(backendLineItem.variant_id)
        continue
      }
    
      // Then find any options for this specific cart line item
      const correspondingBackendOption = backendOptions.find(item => item.product_id === correspondingCatalogEntry.productId)
      let tmpOptions: CartV2SelectedOption[] = []
      if (correspondingBackendOption) {
        // For each option/choice pair of the backend
        for (const backendOptionChoices of correspondingBackendOption.selected_options) {
          // Cross-check the options of the catalog entry to see if it has upgrade SKUs
          // First find the matching option entry...
          const correspondingCatalogOption = correspondingCatalogEntry.backendOptions.find(item => item.systemId === backendOptionChoices.option_id)
          if (!correspondingCatalogOption) {
            return {
              frontendCart: null,
              errorMsg: `Couldn't find option ID ${backendOptionChoices.option_id} in catalog`
            }
          }
          // Then find the choice entry witghin the option...
          const correspondingCatalogChoice = correspondingCatalogOption.choices.find(choice => choice.systemId === backendOptionChoices.choice_id)
          if (!correspondingCatalogChoice) {
            return {
              frontendCart: null,
              errorMsg: `Couldn't find choice ID ${backendOptionChoices.option_id} in catalog`
            }
          }
          if (correspondingCatalogChoice.addedCostVariantId){
            seenUpgradeVariantIds.push(correspondingCatalogChoice.addedCostVariantId)
          }
          // Then use its added cost variant ID
          tmpOptions.push({
            option_id: backendOptionChoices.option_id,
            choice_id: backendOptionChoices.choice_id,
            upgrade_variant_id: correspondingCatalogChoice.addedCostVariantId
          })
        }
      }

      // Now we have both the item and choices data
      const tmpLineItem: CartV2LineItem = {
        variant_id: backendLineItem.variant_id,
        product_id: correspondingCatalogEntry.productId,
        quantity: backendLineItem.quantity,
        options: tmpOptions
      }
      tmpCart.unshift(tmpLineItem)
    }

    if(!missingBackendCartVariantIds.every(variantId => seenUpgradeVariantIds.includes(variantId))){
      return {
        frontendCart: null,
        errorMsg: `Couldn't find matching catalog entry for all variant ids`
      }
    }

    // Done building the cart
    return {
      frontendCart: tmpCart,
      errorMsg: null
    }
  }