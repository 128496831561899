import React from "react"
import {
  Box,
  Text,
  Heading,
  Code,
  Button,
  Stack,
  Flex,
  Wrap,
} from "@chakra-ui/react"
import { useGlobalContext } from "../DataManagement/GlobalContextManager"
import { ErrorType, ErrorTypes } from "../DataManagement/Types"
import { WarningTwoIcon } from "@chakra-ui/icons"
import { useNavigate } from "react-router-dom"


export const ErrorScreen: React.FC<{
  error: ErrorType
}> = ({
  error
}) => {

  const globalContext = useGlobalContext()
  const navigate = useNavigate()

  if (error.type === ErrorTypes.MissingSubId) {
    globalContext.setSubscriptionId(null)
    // LONG-TERM: Make sure this can't occur if someone comes back to GB after GB has ended and polls
    return (
      <Flex direction="column">
        <WarningTwoIcon boxSize="5em" />
        <Heading as="h4" mb={4}>Error</Heading>
        <Text>Please report this error to CannonKeys:</Text>
        <Code>Subscription was deleted during polling without action from original creator</Code><br/>
        <Button mt={4} colorScheme="whiteAlpha" onClick={() => navigate("/reset")}>Main Menu</Button>
      </Flex>
    )
  } else if (error.type === ErrorTypes.NoSubIdReturned) {
    globalContext.setSubscriptionId(null)
    return (
      <Box>
        <WarningTwoIcon boxSize="5em" />
        <Heading as="h4" mb={4}>Failed to join raffle</Heading>
        <Text>Please report this error to CannonKeys:</Text>
        <Code>Subscription creation returned no or blank subscription ID</Code><br/>
        <Button mt={4} colorScheme="whiteAlpha" onClick={() => navigate("/reset")}>Main Menu</Button>
      </Box>
    )
  } else if (error.type === ErrorTypes.CartEditError) {
    return (
      <Box>
        <WarningTwoIcon boxSize="5em" />
        <Heading as="h4" mb={4}>Failed to edit cart</Heading>
        <Text>Please report this error to CannonKeys:</Text>
        <Code>Cart editing failed: {error.message}</Code><br/>
        <Button mt={4} colorScheme="whiteAlpha" onClick={() => navigate("/reset")}>Main Menu</Button>
      </Box>
    )
  } else if (error.type === ErrorTypes.OrderEditFetchError) {
    return (
      <Box>
        <WarningTwoIcon boxSize="5em" />
        <Heading as="h4" mb={4}>Failed to retrieve order for editing</Heading>
        <Text>Please report this error to CannonKeys:</Text>
        <Code>{error.message}</Code><br/>
        <Button mt={4} colorScheme="whiteAlpha" onClick={() => navigate("/reset")}>Main Menu</Button>
      </Box>
    )
  } else if (error.type === ErrorTypes.GlobalContextError) {
    return (
      <Box>
        <WarningTwoIcon boxSize="5em" />
        <Heading as="h4" mb={4}>System Error</Heading>
        <Text>Please report this error to CannonKeys:</Text>
        <Code>Global context reports the following - {globalContext.errorMsg}</Code><br/>
        <Button mt={4} colorScheme="whiteAlpha" onClick={() => navigate("/reset")}>Main Menu</Button>
      </Box>
    )
  } else if (error.type === ErrorTypes.CartManagerError) {
    return (
      <Box>
        <WarningTwoIcon boxSize="5em" />
        <Heading as="h4" mb={4}>System Error</Heading>
        <Text>Please report this error to CannonKeys:</Text>
        <Code>Cart manager reports the following - {error.message}</Code><br/>
        <Button mt={4} colorScheme="whiteAlpha" onClick={() => navigate("/reset")}>Main Menu</Button>
      </Box>
    )
  } else if (error.type === ErrorTypes.GenericFailedCreateSubAxios || error.type === ErrorTypes.GenericFailedCreateSub) {
    return (
      <Box alignItems="center">
        <WarningTwoIcon boxSize="5em" />
        <Heading as="h4" mb={4}>Failed to join raffle</Heading>
        <Text>Please double-check your internet connection and try again.<br />If the issue persists, please contact CannonKeys with the details below.</Text>
        <Text mt={4}>More info:</Text>
        <Code>{error.message}</Code><br/>
        <Button mt={4} colorScheme="whiteAlpha" onClick={() => navigate("/reset")}>Main Menu</Button>
      </Box>
    )
  } else {
    return (
      <Box>
        <WarningTwoIcon boxSize="5em" />
        <Heading as="h4" mb={4}>Failed to verify raffle status</Heading>
        <Text>Please double-check your internet connection and try again.<br />If the issue persists, please contact CannonKeys with the details below.</Text>
        <Text mt={4}>More info:</Text>
        <Code>{error.message}</Code><br/>
        <Button mt={4} colorScheme="whiteAlpha" onClick={() => navigate("/reset")}>Main Menu</Button>
      </Box>
    )
  }



  }