import React from "react"
import {
  Box,
  Heading,
  Container,
  Stack,
  HStack,
  Button,
  VStack,
  Text,
} from "@chakra-ui/react"
import { ReactComponent as LogoWhite } from '../../assets/LogoWhite-NoText.svg'
import { useNavigate } from "react-router-dom"

// 404 page
export const FallbackView: React.FC<{}> = () => {

  const navigate = useNavigate()

    return (
      <Box w="100%" minH="100vh" bgColor="ckNavy" textColor="white" textAlign="center" alignItems="center" justifyItems="center" justifyContent="center" alignContent="center" pt={8}>
        <Stack direction="column" alignItems="center" justifyItems="center" justifyContent="center" alignContent="center">
          <LogoWhite width="6em" height="6em" />
          <HStack alignItems="top" >
            <Heading as="h1" fontSize="32px" fontWeight="600">404</Heading>
          </HStack>
        </Stack>

        <Container maxW="container.md" mt={12}>
          <VStack>
          <Text>Page not found.</Text>
          <Button onClick={() => navigate("/")} variant="outline">Main Menu</Button>
          </VStack>

        </Container>

      </Box>
    )
  }