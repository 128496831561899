import React, { useEffect, useState } from "react"
import {
  Text,
  Button,
  Input,
  VStack,
  Wrap,
  FormControl,
  FormHelperText,
  FormLabel,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react"
import { useGlobalContext } from "../DataManagement/GlobalContextManager"
import { BasePageTemplate } from "./BasePageTemplate"
import { Navigate, useNavigate } from "react-router-dom"

import { useCartManagerV2 } from "../DataManagement/CartManagerV2"
import axios from "axios"
import { useMutation } from "react-query"
import { LookupOrderBody, lookupOrder, LookupOrderResponse } from "../../api/backend"
import { convertCartToFrontendForm } from "../DataManagement/CartManagerV2Utils"
import { ErrorScreen } from "../CommonComponents/ErrorScreen"
import { ErrorTypes } from "../DataManagement/Types"

export const EditOrderLookupView: React.FC<{}> = () => {

  const globalContext = useGlobalContext()
  const cartManagerV2 = useCartManagerV2()
  const navigate = useNavigate()

  const [email, setEmail] = useState<string | null>(null)
  const [orderNum, setOrderNum] = useState<number | null>(null)
  const [subId, setSubId] = useState<string | null>(null)

  const [invalidInputs, setInvalidInputs] = useState<string[]>([])
  const [lookupError, setLookupError] = useState<string | null>(null)

  // Lookup error = soft errors on invalid inputs, errorMsg = hard error on system fail
  const [errorMsg, setErrorMsg] = useState<string | null>(null)


  // Query to lookup an order
  // React-query caches the data

  const submitOrderLookup = useMutation((input: LookupOrderBody) => lookupOrder(input), {
    onError: error => {
      // If creating a subscr fails
      console.error(error);
      // If axios error, can fetch a useful message 
      if (axios.isAxiosError(error)) {

        // If a response exists, can glean info from it
        if (error.response) {

          // If 404, is a case of order-not-found
          if (error.response.status === 404) {
            if (error.response.data) {
              let errorMsg = (error.response.data as LookupOrderResponse)?.error
              setLookupError(errorMsg || "Order not found.")
            } else {
              setLookupError("Order not found.")
            }
          }
          // Otherwise, some worse error is happening
          else {
            setLookupError(`Failed to fetch the order details - ${error.message} A. If this issue persists, please contact CannonKeys support.`)
          }
        }

        // If no response, just print out the details
        else {
          setLookupError(`Failed to fetch the order details - ${error.message} B. If this issue persists, please contact CannonKeys support.`)
        }
      }

      // If not even an axios error, need to just do a best attempt
      else {
        setLookupError(`Failed to fetch the order details - ${JSON.stringify(error)} C. If this issue persists, please contact CannonKeys support.`)
      }
    },
    onSuccess: data => {
      // Store successfully fetched order details and move to the next screen
      // Next screen will query the same info
      if (!data.collection_title) {
        // Faulty return data
        setErrorMsg("Please report the following to CannonKeys: Edit lookup - Order data fetch successful but data empty")
        return
      }
      globalContext.setOrderEditDetails({ email: email, orderNum: orderNum, subId: subId })
      globalContext.setCollection(data.collection_title)
    },
    onSettled: () => {
    },
    retry: (failureCount, error) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          return false
        }
      }
      return failureCount < 3
    }
  })


  // Once data is ready, load it into cart and navigate
  useEffect(() => {
    if (submitOrderLookup.data && globalContext.selectedCollection?.catalog) {
      const cartData = convertCartToFrontendForm(submitOrderLookup.data?.cart?.line_items!, submitOrderLookup.data?.selected_options?.options!, globalContext.selectedCollection?.catalog!)
      if (cartData.errorMsg) {
        setErrorMsg("Please report the following to CannonKeys: Edit lookup - Cart data transform failed")
      } else {
        cartManagerV2.importCart(cartData.frontendCart!)
        navigate("/edit/cart")
      }
    }
  }, [submitOrderLookup, globalContext.selectedCollection])


  // The UI-called submit routine
  const onSubmitPressed = () => {
    let tempInvalidInputs = []
    if (!email || email === "") {
      tempInvalidInputs.push("email")
    }
    if (!orderNum || orderNum < 1) {
      tempInvalidInputs.push("orderNum")
    }
    if (!subId || subId === "") {
      tempInvalidInputs.push("subId")
    }
    setInvalidInputs(tempInvalidInputs)
    if (tempInvalidInputs.length > 0) {
      return
    }
    submitOrderLookup.mutate({ email: email!, order_number: orderNum!, subscription_id: subId! })
    // setLookupQueryEnabled(true)
  }


  if (globalContext.isError) {
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Raffling"}>
        <ErrorScreen error={({ type: ErrorTypes.GlobalContextError, message: "" })} />
      </BasePageTemplate>
    )
  } else if (cartManagerV2.isError) {
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Raffling"}>
        <ErrorScreen error={({ type: ErrorTypes.CartManagerError, message: cartManagerV2.statusMsg || "" })} />
      </BasePageTemplate>
    )
  } else if (errorMsg) {
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Raffling"}>
        <ErrorScreen error={({ type: ErrorTypes.Generic, message: errorMsg })} />
      </BasePageTemplate>
    )
  } else if (globalContext.subscriptionId) {
    return <Navigate to="/raffle" />
  }
  return (
    <BasePageTemplate maxW="container.sm" headerText="Edit Order">
      <VStack bgColor="white" rounded="md" width="100%" textColor="black" minH="100%" py={8} px={4} spacing={4}>

        <Text pb={4}>Please enter your order details.</Text>

        {lookupError &&
          <Text textColor="red" fontWeight="semibold">Error: {lookupError}</Text>
        }

        <FormControl textAlign="start" isDisabled={submitOrderLookup.isLoading}>
          <FormLabel>Email address used for checkout</FormLabel>
          <Input
            isDisabled={submitOrderLookup.isLoading}
            type="email"
            placeholder='captain.kobe@cannonkeys.com'
            onChange={(e) => {
              setEmail(e.target.value)
              setInvalidInputs(invalidInputs.filter(item => item !== "email").slice())
            }}
            isInvalid={invalidInputs.includes("email")} />
          {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
        </FormControl>

        <FormControl textAlign="start" isDisabled={submitOrderLookup.isLoading}>
          <FormLabel>Order number</FormLabel>
          <InputGroup>
            <InputLeftAddon children="#" />
            <Input
              isDisabled={submitOrderLookup.isLoading}
              type="number"
              placeholder='Order number'
              onChange={(e) => {
                setOrderNum(e.target.valueAsNumber)
                setInvalidInputs(invalidInputs.filter(item => item !== "orderNum").slice())
              }}
              isInvalid={invalidInputs.includes("orderNum")} />
          </InputGroup>
          {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
        </FormControl>

        <FormControl textAlign="start" isDisabled={submitOrderLookup.isLoading}>
          <FormLabel>Subscription ID</FormLabel>
          <Input
            isDisabled={submitOrderLookup.isLoading}
            placeholder='abcdef12-3456-7890-abcd-ef1234567890'
            onChange={(e) => {
              setSubId(e.target.value)
              setInvalidInputs(invalidInputs.filter(item => item !== "subId").slice())
            }}
            isInvalid={invalidInputs.includes("subId")} />
          <FormHelperText>Please check your email for this code.</FormHelperText>
        </FormControl>

        <Wrap direction="row" justify="center" pt={4}>
          <Button
            colorScheme="gray"
            onClick={() => navigate("/")}
          >
            Back to Main Menu
          </Button>
          <Button
            colorScheme="ck"
            onClick={() => onSubmitPressed()}
            isLoading={submitOrderLookup.isLoading}
          >
            Edit Order
          </Button>
        </Wrap>
      </VStack>

    </BasePageTemplate>
  )
}