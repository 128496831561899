import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Stack,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useGlobalContext } from "../DataManagement/GlobalContextManager";
import { CartV2LineItem } from "../DataManagement/Types";
import { CatalogEntryV2 } from "../CartBuilderView/CatalogEntryV2";
import { useCartManagerV2 } from "../DataManagement/CartManagerV2";


export const AddItemModal: React.FC<{
  isOpen: boolean,
  onClose: () => void,
}> = ({
  isOpen,
  onClose}) => {

    const globalContext = useGlobalContext()
    const cartManagerV2 = useCartManagerV2()


    const addToCart = (item: CartV2LineItem) => {
      const editResponse = cartManagerV2.addToCart(item)
      // console.log(editResponse)
      if (editResponse.success) {
        onClose()
      }
      return editResponse
    }


    return (
      <Modal size="full" isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textColor="white" bgColor="gray.700">Add Items</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody bgColor="gray.700">
            <Stack direction="column" spacing="24px" justify="center" pb={16}>
              {globalContext.selectedCollection!.catalog.map(item => (
                <CatalogEntryV2 item={item} addToCart={addToCart} key={`catalog-item-${item.productId}-${item.variantId}`} />
              ))}
            </Stack>
          </ModalBody>

          <ModalFooter bgColor="gray.700">
            <Button colorScheme="whiteAlpha" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );

  };
