import React, { useState } from "react"
import {
  Text,
  Button,
  VStack,
} from "@chakra-ui/react"
import { BasePageTemplate } from "./BasePageTemplate"
import { useNavigate } from "react-router-dom"


export const EditOrderDecisionView: React.FC<{}> = () => {

  const navigate = useNavigate()

  const [nonGbEdit, setNonGbEdit] = useState<boolean>(false)


  if (nonGbEdit) {
    return (
      <BasePageTemplate maxW="container.sm" headerText="Edit Order">
        <VStack bgColor="white" rounded="md" width="100%" textColor="black" minH="100%" py={8} px={4} spacing={4}>
  
          <Text pb={4}>For editing standard orders placed through the CannonKeys webstore, please visit the self-service system.</Text>
  
            <Button colorScheme="ck" onClick={() => (window as any).open("https://self-service.cannonkeys.com/login", '_blank').focus()} style={{ whiteSpace: "normal", wordWrap: "break-word" }} h="auto" py={3}>
              <Text>Self-Service Portal</Text>
            </Button>
            <Button onClick={() => navigate("/")} style={{ whiteSpace: "normal", wordWrap: "break-word" }} h="auto" py={3}>
              Back to main menu
            </Button>
  
        </VStack>
      </BasePageTemplate>
    )
  } else {
    return (
      <BasePageTemplate maxW="container.sm" headerText="Edit Order">
        <VStack bgColor="white" rounded="md" width="100%" textColor="black" minH="100%" py={8} px={4} spacing={4}>
  
          <Text pb={3}>Please select where you placed your order.</Text>
  
            <Button colorScheme="ck" onClick={() => setNonGbEdit(true)} style={{ whiteSpace: "normal", wordWrap: "break-word" }} h="auto" py={3}>
              Main CannonKeys Webstore
            </Button>
            <Button colorScheme="ck" onClick={() => navigate("/edit/lookup")} style={{ whiteSpace: "normal", wordWrap: "break-word" }} h="auto" py={3}>
              Hype V2
            </Button>
            <Button onClick={() => navigate("/")} style={{ whiteSpace: "normal", wordWrap: "break-word" }} h="auto" py={3}>
              Back to Main Menu
            </Button>
  
        </VStack>
      </BasePageTemplate>
    )
  }
}