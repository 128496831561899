import {
  ChakraProvider,
  extendTheme,
  withDefaultColorScheme,
} from "@chakra-ui/react"
import { Routes, Route } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "react-query"

import { LandingView } from "./components/LandingView/LandingView"
import { GlobalContextManager } from "./components/DataManagement/GlobalContextManager"
import { InRaffleView } from "./components/InRaffleView/InRaffleView"
import { JoinRaffleView } from "./components/JoinRaffleView/JoinRaffleView"
import { ScrollManager } from "./components/CommonComponents/ScrollManager"
import { RaffleClosedView } from "./components/RaffleEndedViews/RaffleClosedView"
import { RaffleSoldOutView } from "./components/RaffleEndedViews/RaffleSoldOutView"
import { EditOrderLookupView } from "./components/EditOrderView/EditOrderLookupView"
import { EditOrderModifyView } from "./components/EditOrderView/EditOrderModifyView"
import { CartV2BuilderView } from "./components/CartBuilderView/CartV2BuilderView"
import { CartV2CheckoutView } from "./components/CartBuilderView/CartV2CheckoutView"
import { CartManagerV2 } from "./components/DataManagement/CartManagerV2"
import { FallbackView } from "./components/FallbackView/FallbackView"
import { ResetView } from "./components/ResetView/ResetView"
import { EditOrderDecisionView } from "./components/EditOrderView/EditOrderDecisionView"
import { EditOrderSubmitView } from "./components/EditOrderView/EditOrderSubmitView"

// TODO: Fix issue where subid clears randomly after much time and kicks people out of the queue

const customTheme = extendTheme(
  withDefaultColorScheme({
    colorScheme: 'ck',
    components: ['Input'],
  }),
  {
    colors: {
      ckStatic: "#364291",
      ckNavy: "#060D44",
      ck: {
        50: '#e9eeff',
        100: '#c6ccee',
        200: '#a2aadd',
        300: '#7d88cf',
        400: '#5966c0',
        500: '#3f4ca6',
        600: '#303b82',
        700: '#222a5e',
        800: '#13193b',
        900: '#05071a',
      }
    },
    fonts: {
      heading: `'Inter', sans-serif`,
      body: `'Inter', sans-serif`,
    },
  }
)

const queryClient = new QueryClient();

export const App = () => {

  return (
    <ChakraProvider theme={customTheme}>
      <QueryClientProvider client={queryClient}>
        <GlobalContextManager>
          <CartManagerV2>
            <Routes>

              {/* Landing */}
              <Route path="/" element={<LandingView />} />

              {/* Cart */}
              <Route path="cart/checkout" element={<CartV2CheckoutView />} />
              <Route path="cart" element={<CartV2BuilderView />} />

              {/* Subscription and raffle flow */}
              <Route path="subscribe" element={<JoinRaffleView />} />
              <Route path="raffle" element={<InRaffleView />} />
              <Route path="raffle/closed" element={<RaffleClosedView />} />
              <Route path="raffle/sold-out" element={<RaffleSoldOutView />} />

              {/* Editing flow */}
              <Route path="edit" element={<EditOrderDecisionView />} />
              <Route path="edit/lookup" element={<EditOrderLookupView />} />
              <Route path="edit/cart" element={<EditOrderModifyView />} />
              <Route path="edit/submit" element={<EditOrderSubmitView />} />

              {/* Reset all stored data if sth breaks */}
              <Route path="reset" element={<ResetView />} />

              {/* 404 */}
              <Route path="*" element={<FallbackView />} />
              
            </Routes>
          </CartManagerV2>
        </GlobalContextManager>
        <ScrollManager />
      </QueryClientProvider>
    </ChakraProvider>
  )
}