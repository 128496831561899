import { CartV2LineItem } from "./Types";



// Set currently selected collection handle
export const setStoredCollection: (collectionHandle: string) => void = (collectionHandle) => {
  localStorage.setItem("collection", collectionHandle)
}

// Get stored collection handle
// Returns null if DNE
export const getStoredCollection: () => string | null = () => {
  return localStorage.getItem("collection")
}

// Clear stored collection handle
export const clearStoredCollection: () => void = () => {
  localStorage.removeItem("collection");
}

// Set localstorage subscr ID
export const setStoredSubId: (id: string) => void = (id) => {
  localStorage.setItem("subscriptionId", id)
}

// Get localstorage subscr ID
// Returns null if DNE
export const getStoredSubId: () => string | null = () => {
  return localStorage.getItem("subscriptionId")
}

// Clear stored subscr ID
export const clearStoredSubId: () => void = () => {
  return localStorage.removeItem("subscriptionId")
}



// Set localstorage cartv2 contents 
export const setStoredCartV2: (cart: CartV2LineItem[]) => void = (cart) => {
  localStorage.setItem("cartv2", JSON.stringify(cart))
}

// Get localstorage cartv2 contents
// Returns null if DNE
export const getStoredCartV2: () => CartV2LineItem[] | null = () => {
  const cartString = localStorage.getItem("cartv2")
  if (cartString) {
    const cart = JSON.parse(cartString) as CartV2LineItem[]
    return cart
  } else {
    return null
  }
}

// Clear stored cart
export const clearStoredCartV2: () => void = () => {
  localStorage.removeItem("cartv2");
}

