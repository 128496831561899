import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";


export const ConfirmEditModal: React.FC<{
  isOpen: boolean,
  onClose: () => void,
}> = ({
  isOpen,
  onClose}) => {

    const navigate = useNavigate()

    return (
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Changes</ModalHeader>
          <ModalBody>
            <Text mb={4}>If your cart value has increased, you will receive an invoice for the difference via email.</Text>
            <Text mb={8}>Not paying the received invoice can cause your entire order to be cancelled.</Text>
            <Text mt={4}>Please double-check that you are sure about the changes that you have made, then click confirm to proceed.</Text>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>

            <Button
              colorScheme="red"
              onClick={() => navigate("/edit/submit")}
            >Submit Changes</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );

  };
