import {
  editOrder,
  EditOrderBody,
  getEditOrderStatus,
  GetEditOrderStatusResponse
} from "../api/backend";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useState } from "react";
import axios from "axios";

const retryCheck = (failureCount: number, error: unknown) => {
  if (axios.isAxiosError(error)) {
    // LONG-TERM: Add more robust error handling for other codes
    if (error.response?.status === 429) {
      return true;
    } else {
      return failureCount < 3
    }
  } else {
    return failureCount < 3
  }
}

const useEditOrderQuery = ({
  onSuccess,
  onError,
  inputs,
}: {
  onSuccess: (data: GetEditOrderStatusResponse) => any;
  onError: (error: Error) => any;
  inputs: EditOrderBody;
}) => {
  const [jobId, setJobId] = useState<string>("");
  const [stop, setStop] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const { mutate } = useMutation(() => editOrder(inputs), {
    onMutate: () => {
      setStop(false);
    },
    onError: (error) => {
      setStop(true);
      if (error instanceof Error) {
        console.error(error);
        onError(error);
      }
    },
    onSuccess: (data) => {
      if (data.job_id) {
        console.log("received job id")
        setJobId(data.job_id);
      } else {
        onError(Error("Job ID returned null"))
      }
    },
    retry: retryCheck,
    retryDelay: 2000,
  });

  useQuery(
    ["editOrderProgress", jobId],
    () => getEditOrderStatus(jobId),
    {
      onSuccess: (data) => {
        console.log("queried")
        console.log(data)
        if (data.complete) {
          console.log("complete")
          if (!data.success) {
            onError(Error("Edit reported failed by backend"))
            return
          }
          setStop(true);
          setJobId("");
          onSuccess(data);
          // console.log(inputs.orderId);
          queryClient.resetQueries(["getOrderById", jobId]);
        } else {
          console.log("not complete")
        }
      },
      onError: (error) => {
        setStop(true);
        setJobId("");
        if (error instanceof Error) {
          console.error(error);
          onError(error);
        }
      },
      retry: retryCheck,
      retryDelay: 2000,
      enabled: jobId !== "",
      refetchInterval: stop ? false : 5000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
    }
  );

  return { mutate };
};

export default useEditOrderQuery;
