import React, { useEffect, useRef, useState } from "react"
import { DateTime } from "luxon"
import {
  Box,
  Button,
  Collapse,
  Container,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react"
import { useMutation } from "react-query"
import { checkSubscription } from "../../api/backend"
import { useGlobalContext } from "../DataManagement/GlobalContextManager"
import { Navigate } from "react-router-dom"
import { useCartManagerV2 } from "../DataManagement/CartManagerV2"
import { BasePageTemplate } from "./BasePageTemplate"
import axios from "axios"
import { ErrorScreen } from "../CommonComponents/ErrorScreen"
import { LeaveRaffleModal } from "./LeaveRaffleModal"
import { ErrorType, ErrorTypes } from "../DataManagement/Types"
import { CartDisplay } from "./CartDisplay"
import { BarLoader } from "react-spinners"


export const InRaffleView: React.FC<{}> = () => {

  const globalContext = useGlobalContext()
  const cartManagerV2 = useCartManagerV2()


  const subscriptionIdRef = useRef(globalContext.subscriptionId);
  subscriptionIdRef.current = globalContext.subscriptionId;
  const oosReportedRef = useRef(cartManagerV2.cartItemsExceedingAvailableStock.length > 0)
  oosReportedRef.current = cartManagerV2.cartItemsExceedingAvailableStock.length > 0;
  // For disabling periodical polling behavior on mount 
  // Two possibilities:
  // - globalContext's selectedCollection is null -> Page was likely refreshed, needs some time to prepare itself.
  //   In this case, firstRun is set to true to tank the first run (useEffect run on load), then a separate code block sets lastRefresh when ready to fire the chain
  // - globalContext's selectedCollection exists -> Someone likely navigated to the page with the context already loaded
  //   In this case, let the first useEffect run proceed as is
  const firstRun = useRef(globalContext.selectedCollection ? false : true);
  const [lastRefresh, setLastRefresh] = useState<DateTime | null>(null)
  const [errorMsg, setErrorMsg] = useState<ErrorType | null>(null)
  const [checkoutUrl, setCheckoutUrl] = useState<string>("")
  const { isOpen: isLeaveRaffleModalOpen, onOpen: onLeaveRaffleModalOpen, onClose: onLeaveRaffleModalClose } = useDisclosure();
  const [showCart, setShowCart] = useState(false);
  const [hideCartDueToError, setHideCartDueToError] = useState<boolean>(false)


  const everythingLoaded = !globalContext.isLoading && !cartManagerV2.isLoading
  const everythingNotError = !globalContext.isError && !cartManagerV2.isError

  // console.error("DEBUG A")
  // console.log(globalContext)



  // Mutation to run periodic check
  // Purposely not react-query since easier to control exact time of fire and side effects while having no benefit to cache
  const checkSubscriptionMutation = useMutation((subscriptionId: string) => checkSubscription(subscriptionId), {
    onError: error => {
      console.log(error)
      // If axios error (networking error) can pull out more meaningful data
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          setErrorMsg({
            type: ErrorTypes.MissingSubId,
            message: ""
          })
        } else if (error.response?.status === 429) {
          // Rate limited - just reschedule
          setLastRefresh(DateTime.now())
        } else {
          setErrorMsg({
            type: ErrorTypes.GenericAxios,
            message: error.message
          })
        }
      }
      // If not an axios error, need to fetch data another way
      else {
        setErrorMsg({
          type: ErrorTypes.Generic,
          message: JSON.stringify(error)
        })
      }
    },
    onSuccess: data => {
      console.log("Check success.")
      console.log(data)
      // If a checkout URL was given, set it so the page navigates to it
      if (data.checkoutUrl && data.checkoutUrl !== "") {
        setCheckoutUrl(data.checkoutUrl)
        globalContext.setSubscriptionId(null)
      }
      // Otherwise, just set the last refreshed time to trigger the next 30 second wait
      else {
        setLastRefresh(DateTime.now())
      }
    },
    retry: (failureCount, error) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 429) {
          // In this case, the onError method handles this 
          // Sets last refresh if status === 429
          // So don't retry automatically
          return false
        } else if (error.response?.status === 404) {
          return false
        }
      }
      return failureCount < 3
    }
  })


  // Fire off periodic check iff everything is loaded
  if (everythingLoaded && everythingNotError && !lastRefresh) {
    console.log("Everything loaded, run periodic task")
    setLastRefresh(DateTime.now())
  }

  // Periodic check task
  // Fired when lastRefesh is modified
  useEffect(() => {
    console.log("c")

    // Tank first run - nothing is ready 
    if (firstRun.current) {
      console.log("Tanking first periodic run attempt onLoad")
      firstRun.current = false
      return
    }

    console.log("Scheduling check in 30 seconds")

    const timeout = setTimeout(() => {

      // Once 30 seconds have passed, take a few possible choices
      console.log("Subscription check routine called")
      console.log(DateTime.now().toString())

      // If in raffling stage and hasn't sold out
      if (globalContext.collectionTimeState === "RAFFLING" && subscriptionIdRef.current && !oosReportedRef.current) {
        console.log("Sending network request")
        // Fire off the networking 
        // Once it returns, it will set lastRefresh and cause this task to be re-scheduled, guaranteeing a span of over 30 seconds regardless of networking response time drift
        checkSubscriptionMutation.mutate(subscriptionIdRef.current)
      }
      // Otherwise (in pre-raffle or sub ID is somehow not ready 30 seconds after loading the screen)
      else {
        console.log("Not firing network task - await 30 seconds")
        // Don't fire a networking request since worthless to do so
        // Instead just wait the timeout and re-fire this task
        setLastRefresh(DateTime.now())
      }

    }, globalContext.inStockModeSwitch ? 5 * 1000 : 30 * 1000); // Every 30 seconds if raffling, every 5 seconds if instock. LONG-TERM: Change this to envvar

    return () => clearTimeout(timeout);

    // Forcibly disable errors here - this should never run except for the very first time it's ready
    // TODO: See if there's a better solution

  }, [lastRefresh]);


  // If cart data is empty (i.e. page was refreshed), attempt to load it 
  // If it fails to load, redir out or show an error 
  useEffect(() => {
    console.log("a")
    // LONG-TERM: This is called every render
    // Optimize by not making it called every render
    if (!cartManagerV2.isError && !cartManagerV2.isLoading && cartManagerV2.cart.length <= 0) {
      console.log("aa")
      const ret = cartManagerV2.loadSavedCart()
      if (!ret.success) {
        setHideCartDueToError(true)
      }
    }
  }, [cartManagerV2, globalContext])


  // If a checkout URL is set, navigate to it
  // Redirect user while wiping saved data
  useEffect(() => {
    console.log("b")
    if (checkoutUrl !== "") {
      // Redirect user while wiping saved data
      globalContext.setCollection("")
      globalContext.setSubscriptionId(null)
      cartManagerV2.deleteSavedCart()
      cartManagerV2.clearCart()
      window.location.replace(checkoutUrl)
    }
  }, [checkoutUrl])


  // Toggle header text depending on in stock mode switch
  const headerText = globalContext.inStockModeSwitch ? "Creating Checkout" : "Raffling"


  // Renderings

  // If a checkout URL is set
  if (checkoutUrl !== "") {
    // Above useEffect handles the navigation and logic
    // Just display a placeholder while it's working
    return (
      <BasePageTemplate maxW="container.lg" headerText={globalContext.inStockModeSwitch ? "Creating Checkout" : "Winner"}>
        {globalContext.inStockModeSwitch ?
          <>
            <Text fontSize="lg" fontWeight="semibold">Loading the checkout...</Text>
          </>
          :
          <>
            <Text fontSize="lg" fontWeight="semibold">You've been selected as a winner!</Text>
            <Text mt={2}>Loading the checkout...</Text>
          </>
        }

        {/* <Text mb={8}>Subscription ID: {globalContext.subscriptionId}</Text> */}
        <Container mt={8} pb={2}>
          <VStack w="100%">
            <BarLoader
              loading={true}
              color="white"
              height="5px"
              width="100%"
            />
          </VStack>
        </Container>
      </BasePageTemplate>
    )
  }

  // If there's an error, show a meaningful error screen
  else if (errorMsg) {
    return (
      <BasePageTemplate maxW="container.lg" headerText={headerText}>
        <ErrorScreen error={errorMsg} />
      </BasePageTemplate>
    )
  }

  // If either cart manager or global context has an error, display those
  else if (globalContext.isError) {
    return (
      <BasePageTemplate maxW="container.lg" headerText={headerText}>
        <ErrorScreen error={({ type: ErrorTypes.GlobalContextError, message: "" })} />
      </BasePageTemplate>
    )
  } else if (cartManagerV2.isError) {
    return (
      <BasePageTemplate maxW="container.lg" headerText={headerText}>
        <ErrorScreen error={({ type: ErrorTypes.CartManagerError, message: cartManagerV2.statusMsg || "" })} />
      </BasePageTemplate>
    )
  }

  else if (!globalContext.isLoading && !globalContext.selectedCollection) {
    console.error("Potential deadlock situation encountered")
    // Deadlock
    globalContext.setSubscriptionId(null)
    cartManagerV2.deleteSavedCart()
    globalContext.setCollection("")
    return <Navigate to="/" />
  }

  // Wait for contexts to loadload
  else if (globalContext.isLoading || cartManagerV2.isLoading) {
    return (
      <BasePageTemplate maxW="container.lg" headerText={headerText}>
        <Spinner />
        <Text mt={2} mb={8}>Loading...</Text>
      </BasePageTemplate>
    )
  }

  // If no collection was specified, nav out
  else if (globalContext.selectedCollectionHandle === "") {
    console.log("In raffle view: Rejecting visitor due to no collection selected")
    return <Navigate to="/" />
  }

  // If no sub id, nav out
  else if (!globalContext.subscriptionId) {
    // If cart still exists, ret to cart
    if (cartManagerV2.cart.length > 0) {
      return <Navigate to="/cart" />
    } else {
      console.log("In raffle view: Rejecting visitor due to missing sub id")
      return <Navigate to="/" />
    }
  }

  // If times are invalid, redir out
  else if (globalContext.collectionTimeState === "BEFORE_LAUNCH") {
    console.log("In raffle view: Rejecting visitor due to invalid time")
    return <Navigate to="/" />
  }

  // If GB has already closed via timestamps, return
  else if (globalContext.collectionTimeState === "CLOSED") {
    return <Navigate to={"/raffle/closed"} />
  }

  // Handle sold out conditions where shopify's reported qties of products in stock are lower than qties in cart
  // LONG-TERM: OR this with backend's reported OOS statistics
  else if (cartManagerV2.cartItemsExceedingAvailableStock.length > 0) {
    return <Navigate to={"/raffle/sold-out"} />
  }

  // If still yet to be raffling, show some other screen
  else if (globalContext.collectionTimeState === "PENDING_RAFFLE") {
    return (
      <>
        <BasePageTemplate maxW="container.lg" headerText={"Awaiting Raffle"}>
          <Text fontSize="lg" fontWeight="semibold">You've entered the raffle.</Text>
          <Text mt={2}>Raffling will begin in {globalContext.selectedCollection?.raffleStartTime.diffNow("minutes").toFormat("mm:ss")}</Text>
          {/* <Text mb={8}>Subscription ID: {globalContext.subscriptionId}</Text> */}
          <Container mt={8} pb={2}>
            <VStack w="100%">
              <BarLoader
                loading={true}
                color="white"
                height="5px"
                width="100%"
              />
            </VStack>
          </Container>
          <Stack direction="column" mt={16}>
            {/* Cart contents */}
            <Box>
              <Button colorScheme="whiteAlpha" onClick={() => setShowCart(!showCart)}>{showCart ? "Hide" : "Show"} Cart Contents</Button>
              <Collapse in={showCart}>
                <CartDisplay />
              </Collapse>
            </Box>
            <Box>
              <Button colorScheme="red" variant="outline" onClick={onLeaveRaffleModalOpen}>Leave Raffle</Button>
            </Box>
          </Stack>
        </BasePageTemplate>
        <LeaveRaffleModal
          isOpen={isLeaveRaffleModalOpen}
          onClose={onLeaveRaffleModalClose}
          onError={setErrorMsg}
        />
      </>
    )
  }

  // Finally, if none of the above fit, just show a raffling screen
  else {
    return (
      <>
        <BasePageTemplate maxW="container.lg" headerText={headerText}>
          {globalContext.inStockModeSwitch ?
            <>
              <Text fontSize="lg" fontWeight="semibold">You'll be transfered to the checkout page when it's ready.</Text>
              <Text mt={2}>This shouldn't take too long...</Text>
            </>
            :
            <>
              <Text fontSize="lg" fontWeight="semibold">You're in the raffle.</Text>
              <Text mt={2}>To hold your spot, stay on this page.</Text>
            </>
          }
          {/* <Text mb={8}>Subscription ID: {globalContext.subscriptionId}</Text> */}
          <Container mt={8} pb={2}>
            <VStack w="100%">
              <BarLoader
                loading={true}
                color="white"
                height="5px"
                width="100%"
              />
            </VStack>
          </Container>

          <Stack direction="column" mt={16}>
            {hideCartDueToError ?
              <Box>
                <Text>Cart contents display could not be loaded.</Text>
                <Text>If the problem persists, please contact CannonKeys.</Text>
              </Box>
              :
              <Box>
                <Button colorScheme="whiteAlpha" onClick={() => setShowCart(!showCart)}>{showCart ? "Hide" : "Show"} Cart Contents</Button>
                <Collapse in={showCart}>
                  <CartDisplay />
                </Collapse>
              </Box>
            }
            <Box>
              <Button colorScheme="red" variant="outline" onClick={onLeaveRaffleModalOpen}>Leave Raffle</Button>
            </Box>
          </Stack>
        </BasePageTemplate>
        <LeaveRaffleModal
          isOpen={isLeaveRaffleModalOpen}
          onClose={onLeaveRaffleModalClose}
          onError={setErrorMsg}
        />
      </>
    )
  }
}

