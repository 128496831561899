import React from "react"
import {
  Box,
  Container,
  Stack,
  HStack,
  Heading,
} from "@chakra-ui/react"

export const BasePageTemplate: React.FC<{
  maxW: string,
  headerIcon: React.ReactNode,
  headerText: string,
  children: React.ReactNode
}> = ({
  maxW,
  headerIcon,
  headerText,
  children
}) => {

    return (
      <Box w="100%" minH="100vh" bgColor="ckNavy" textColor="white" textAlign="center" alignItems="center" justifyItems="center" justifyContent="center" alignContent="center" pt={8}>
        <Stack direction="column" alignItems="center" justifyItems="center" justifyContent="center" alignContent="center">
            {headerIcon}
            <Heading pt={2} as="h1" fontSize="32px" fontWeight="600">{headerText}</Heading>
        </Stack>

        <Container maxW={maxW} mt={12}>
          {children}
        </Container>

      </Box>
    )
  }