import { useMemo } from "react"
import { graphQLClient } from "../../api/clients";
import { GetCollectionByHandleQuery, useGetCollectionByHandleQuery } from "../../generated/graphql";
import { SelectedCollection, QuantityLimitLookupTable, CatalogProductType, UnsplitVariantType, ListedOptionType, UpgradeVariantLookupTable } from "./Types";
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { BackendRelease, getReleases } from "../../api/backend";
import { DateTime } from "luxon";
import axios from "axios";


// useCollection 
// Given a collection handle, load both shopify and backend data, merge them together, and generate a singular collection + catalog structure that can be used by the webshop


const transformRawData = (data: GetCollectionByHandleQuery, releases: BackendRelease[]) => {
  // If data exists (just a sanity check catch)
  if (data.collection) {

    // First, sanity check
    // If we fetched a collection but it's not in the releases, return null
    const correspondingRelease = releases.find(release => release.collection_title === data.collection?.handle)
    if (!correspondingRelease) {
      return null
    }

    // Build a list of options from backend data
    // This can then be filtered by product ID
    type BackendOptionsLookupTable = ListedOptionType & {
      productId: string
    }
    const backendOptionsLookupTable: BackendOptionsLookupTable[] = correspondingRelease.product_options.map(option => {
      return {
        displayName: option.option_label,
        systemId: option.id,
        productId: option.shopify_product_id,
        choices: option.option_choices.map(choice => {
          return {
            displayName: choice.choice_label,
            systemId: choice.id,
            addedCostVariantId: choice.upgrade_variant_id !== "" ? choice.upgrade_variant_id : null
          }
        })
      }
    })

    // Transform each product into the form expected by the frontend
    let catalog: CatalogProductType[] = []
    // let qtyLimits: QuantityLimitType[] = []
    let qtyLimits: QuantityLimitLookupTable = {}
    let upgradeVariants: UpgradeVariantLookupTable = {}

    // Loop products
    for (const productEdge of data.collection.products.edges) {
      const product = productEdge.node


      // If the product is to be treated as an upgrade variant, don't include it in the catalog
      // Instead add it to the upgrade variants lookup table
      if (product.tags.includes("hype-upgrade")) {
        // Add all of the product's variants to the upgrade variants lookup table
        for (const variantEdge of product.variants.edges) {
          const variant = variantEdge.node
          upgradeVariants[variant.id] = parseFloat(variant.priceV2.amount)
        }
        continue
      }


      // From the product's tags, set unit cap if necessary
      if (product.tags.includes("1only")) {
        qtyLimits[product.id] = 1
      }


      // From the product's tags, split its variants as individual items or treat as options

      // If split_variants, take each variant and register it in the catalog
      // Also use this to handle single standalone products (i.e. just one variant)
      if (product.tags.includes("split_variants") || product.variants.edges.length === 1) {

        const isStandalone = product.variants.edges.length === 1

        for (const variantEdge of product.variants.edges) {
          const variant = variantEdge.node

          // Filter out only the backend options applicable to this specific product ID
          const backendOptions = backendOptionsLookupTable.filter(option => option.productId === product.id)

          console.log(`quantityAvailable for variant under product ${product.title}:`)
          console.log(variant.quantityAvailable)

          if (!variant.quantityAvailable) {
            console.log(`quantityAvailable reported null for variant ${variant.id}`)
          }

          const itemToReturn: CatalogProductType = {
            variantId: variant.id,
            productId: product.id,
            productTitle: product.title,
            variantTitle: product.variants.edges.length > 1 ? variant.title : null,
            imageSrc: variant.image?.src || null,
            imageSrcTransformed: variant.image?.transformedSrc || null,
            price: parseFloat(variant.priceV2.amount),
            qtyInStock: variant.quantityAvailable || 0, // LONG-TERM: Figure out when quantityAvailable can be null when stock isn't 0
            backendOptions: backendOptions, // DONE: Correctly integrate options
            unsplitVariants: [],
            productType: isStandalone ? "STANDALONE" : "SPLIT_VARIANTS",
            hypemain: product.tags.includes("hypemain")
          }
          catalog.push(itemToReturn)
        }

      }

      // Otherwise, add the product itself as the catalog entry, and treat its variants as options
      else {

        let unsplitVariants: UnsplitVariantType[] = []

        for (const variantEdge of product.variants.edges) {
          const variant = variantEdge.node

          if (!variant.quantityAvailable) {
            console.log(`quantityAvailable reported null for variant ${variant.id}`)
          }

          // Building the unsplit options for each variant
          unsplitVariants.push({
            displayName: variant.title,
            systemName: variant.title,
            variantId: variant.id,
            imageSrc: variant.image?.src || null,
            imageSrcTransformed: variant.image?.transformedSrc || null,
            qtyInStock: variant.quantityAvailable || 0, // LONG-TERM: Figure out when quantityAvailable can be null when stock isn't 0
            price: parseFloat(variant.priceV2.amount),
          })

        }

        // Filter out only the backend options applicable to this specific product ID
        const backendOptions = backendOptionsLookupTable.filter(option => option.productId === product.id)

        const itemToReturn: CatalogProductType = {
          variantId: null,
          productId: product.id,
          productTitle: product.title,
          variantTitle: null,
          imageSrc: product.variants.edges[0].node.image?.src,
          imageSrcTransformed: product.variants.edges[0].node.image?.transformedSrc,
          price: null,
          qtyInStock: null,
          backendOptions: backendOptions,
          unsplitVariants: unsplitVariants,
          productType: "UNSPLIT_VARIANTS",
          hypemain: product.tags.includes("hypemain")
        }
        catalog.push(itemToReturn)
      }


    }
    // End loop products


    // Sort catalog: The hypemain tag should come first
    catalog.sort((a, b) => {
      if (a.hypemain && !b.hypemain) {
        return -1
      } else if (!a.hypemain && b.hypemain) {
        return 1
      } else {
        return 0
      }
    })


    // Compute timestamps
    const releaseTime = DateTime.fromISO(correspondingRelease.release_time)
    const raffleStartTime = releaseTime.plus({ minutes: correspondingRelease.wait_period_min })
    const closingTime = DateTime.fromISO(correspondingRelease.closing_time)


    // Find whether or not the hypemain product has sold out
    const hypemainProduct = data.collection.products.edges.find(edge => edge.node.tags.includes("hypemain")) || null
    let totalHypemainQty = 0
    if (hypemainProduct) {
      for (const variant of hypemainProduct.node.variants.edges) {
        totalHypemainQty += (variant.node.quantityAvailable || 0)
      }
    }


    return {
      id: data.collection.id,
      releaseId: correspondingRelease.id,
      // title: data.collection.title, 
      // Prefer the backend-provided title over the shopify title so it can be overriden if necessary
      title: correspondingRelease.name,
      imageSrc: data.collection.image?.src || "",
      imageSrcTransformed: data.collection.image?.transformedSrc || "",
      qtyLimits: qtyLimits,
      totalHypemainQty: totalHypemainQty,
      upgradeVariants: upgradeVariants,
      releaseTime: releaseTime,
      raffleStartTime: raffleStartTime,
      closingTime: closingTime,
      secret: correspondingRelease.secret,
      catalog: catalog
    }
  }

  // This shouldn't happen - get success but data.collection doesn't exist
  else {
    return null
  }
}


// ========================= //
// Collection Manager itself


export const useCollectionQuery = (collectionHandle: string, options: UseQueryOptions<GetCollectionByHandleQuery, unknown, GetCollectionByHandleQuery, QueryKey> | undefined) => {


  // Get the backend release data (unconditionally)
  const {
    isLoading: isLoadingReleases,
    isError: isErrorReleases,
    data: releasesData,
    error: releasesError
  } = useQuery('getReleases', () => getReleases(), {
    refetchInterval: 60 * 1000,
    refetchIntervalInBackground: true,
    refetchOnMount: false,
    retry: (failureCount, error) => {
      console.log(error)
      if (axios.isAxiosError(error)) {
        console.log(error)
        if (error.response?.status === 429) {
          return true
        } 
      }
      return failureCount < 3
    }
  })

  // Get the collection if specified
  const {
    isLoading: isLoadingCollection,
    isError: isErrorCollection,
    data: collectionData,
    error: collectionError
  } = useGetCollectionByHandleQuery(graphQLClient, {
    input: collectionHandle,
  }, {
    enabled: (options?.enabled !== null && options?.enabled !== undefined) ? options.enabled && collectionHandle !== "" : collectionHandle !== "",
    refetchInterval: options?.refetchInterval ? options.refetchInterval : 60 * 1000,
    refetchIntervalInBackground: options?.refetchIntervalInBackground ? options.refetchIntervalInBackground : true,
    refetchOnMount: options?.refetchOnMount ? options.refetchOnMount :  false,
    retry: options?.retry ? options.retry : (failureCount, error) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 429) {
          return true
        } 
      }
      return failureCount < 3
    },
    ...options
  })


  // If data has finished fetching, transform it to the output form (detailed collection info + catalog)
  const currentSelectedCollection: SelectedCollection | null = useMemo(() => {
    // If collection handle is null
    if (collectionHandle === "") {
      return null
    }
    // If data is fetched successfully
    if (!isLoadingCollection && !isLoadingReleases && !isErrorCollection && !isErrorReleases && releasesData?.releases && collectionData?.collection) {
      let releases = releasesData?.releases || [];
      return transformRawData(collectionData, releases)
    }
    return null
  }, [isLoadingCollection, isErrorCollection, isLoadingReleases, isErrorReleases, releasesData, collectionData, collectionHandle])


  // Give a human-readable error if sth has failed
  let errorMsg: string | null = null
  if (isErrorCollection) {
    errorMsg = (`Failed to build the product catalog for ${collectionHandle}: Couldn't fetch product data from Shopify. 
Please try again after a short while. 

Details: ${JSON.stringify(collectionError)}`)
  }
  else if (isErrorReleases) {
    errorMsg = (`Failed to build the product catalog for ${collectionHandle}: Couldn't fetch release data from backend. 
Please try again after a short while. 
 
Details: ${JSON.stringify(releasesError)}`)
  }


  // Finally, return the data
  const returnedCollectionData = {
    selectedCollection: currentSelectedCollection,
    isLoading: isLoadingCollection || isLoadingReleases,
    isError: isErrorCollection || isErrorReleases,
    errorMsg: errorMsg
  }

  return returnedCollectionData

}
