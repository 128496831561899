import React, { useEffect, useMemo, useState } from "react"
import {
  Box,
  Text,
  Button,
  Center,
  Flex,
  Stack,
  Wrap,
  AspectRatio,
  Image,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  SkeletonText,
  Skeleton,
} from "@chakra-ui/react"
import { useGlobalContext } from "../DataManagement/GlobalContextManager"
import { CartV2LineItem, CartV2SelectedOption } from "../DataManagement/Types"
import { findMatchingCatalogItemByVariantId, getPriceOfVariantId } from "../DataManagement/CartManagerV2Utils"
import { ReactComponent as LogoWhite } from '../../assets/LogoWhite-NoText.svg'
import { Select } from "chakra-react-select"


export const InCartEntryV2: React.FC<{
  cartItem: CartV2LineItem,
  removeFromCart: (item: CartV2LineItem) => void,
  setCartQuantity: (item: CartV2LineItem, quantity: number) => void,
  setOptions: (item: CartV2LineItem, option: CartV2SelectedOption) => void,
  originalQuantity: number | null,
}> = ({
  cartItem,
  removeFromCart,
  setCartQuantity,
  setOptions,
  originalQuantity
}) => {

  console.log(cartItem.options)

    const globalContext = useGlobalContext()
    const [errorMsg, setErrorMsg] = useState<string | null>(null)
    const [originalItem] = useState<CartV2LineItem>(cartItem)
    useEffect(() => {
      console.log("Original item changed - should ONLY be called once")
      console.log(originalItem)
    }, [originalItem])
    // TODO: Above doesn't work for first-time set only? Or not?

    interface OriginalChoicesTable {
      [optionId: number]: { choiceId: number, upgradeSku: string | null };
    }
    const originalChoices: OriginalChoicesTable = useMemo(() => {
      let tmpChoices: OriginalChoicesTable = {}
      for (const option of originalItem.options) {
        tmpChoices[option.option_id] = {
          choiceId: option.choice_id,
          upgradeSku: option.upgrade_variant_id
        }
      }
      return tmpChoices
    }, [originalItem])




    // If context isn't ready, don't display
    if (globalContext.isLoading) {
      return (
        <Box bgColor="white" rounded="md" width="100%" textColor="black" minH="100%">
          <Stack direction={["column", "column", "row"]}>
            <AspectRatio ratio={[16 / 9, 16 / 9, 4 / 3]} w={["100%", "100%", "500px", "600px"]} >
              <Center w="100%" h="100%" bgColor="gray.500" roundedTopStart="md" roundedBottomStart={["none", "none", "md"]} roundedTopEnd={["md", "md", "none"]}>
                <Spinner />
              </Center>
            </AspectRatio>
            <Flex direction={["column"]} align="start" px={4} pt={[0, 0, 4]} pb={4} textAlign="start" w="100%">
              <Box flex={1} w="100%">
                <Skeleton>
                  <Text fontSize="2xl" fontWeight="semibold" textAlign="start">
                    Loading...
                  </Text>
                </Skeleton>
                <Stack direction="column" my={1} mt={2}>
                  <SkeletonText noOfLines={4} mt={3} />
                </Stack>
              </Box>
              <Wrap mt={4} overflow="visible">
                <Button colorScheme="red" variant="outline" w="auto" mt={3}
                  disabled
                >
                  Remove From Cart
                </Button>
              </Wrap>
            </Flex>
          </Stack>
        </Box>
      )
    } else if (errorMsg) {
      return (
        <Box bgColor="white" rounded="md" width="100%" textColor="black" minH="100%">
          <Stack direction={["column", "column", "row"]}>
            <AspectRatio ratio={[16 / 9, 16 / 9, 4 / 3]} w={["100%", "100%", "500px", "600px"]} >
              <Center w="100%" h="100%" bgColor="gray.500" roundedTopStart="md" roundedBottomStart={["none", "none", "md"]} roundedTopEnd={["md", "md", "none"]}>
                <Spinner />
              </Center>
            </AspectRatio>
            <Flex direction={["column"]} align="start" px={4} pt={[0, 0, 4]} pb={4} textAlign="start" w="100%">
              <Box flex={1} w="100%">
                <Text fontSize="2xl" fontWeight="semibold" textAlign="start">
                  Error
                </Text>
                <Stack direction="column" my={1} mt={2}>
                  <Text>
                    Please report the following error to CannonKeys: <br />
                    {errorMsg}
                  </Text>
                </Stack>
              </Box>
              <Wrap mt={4} overflow="visible">
                <Button colorScheme="red" variant="outline" w="auto" mt={3}
                  disabled
                >
                  Remove From Cart
                </Button>
              </Wrap>
            </Flex>
          </Stack>
        </Box>
      )
    }

    // Get the corresponding catalog product from the line item
    const catalogItem = findMatchingCatalogItemByVariantId(cartItem.variant_id, globalContext.selectedCollection?.catalog!)

    // If item is not found, larger error on hand
    if (!catalogItem) {
      setErrorMsg(`Item ${cartItem.variant_id} has no corresponding catalog item`)
      return null
    }

    // Get quantity limit of item
    const qtyLimit = globalContext.selectedCollection?.qtyLimits[catalogItem.productId]

    let unsplitVariantName: string = ""
    // If item is unsplit variants, get the variant name
    if (catalogItem.productType === "UNSPLIT_VARIANTS") {
      unsplitVariantName = catalogItem.unsplitVariants.find(variant => variant.variantId === cartItem.variant_id)?.displayName || ""
      if (!unsplitVariantName) {
        setErrorMsg(`Unsplit variant item ${catalogItem.productTitle} of variant ID ${cartItem.variant_id} has no name`)
        return null
      }
    }

    // Build a mapping of cart options to backend options


    // Build options dropdowns

    let optionsDropdowns: {
      optionName: string,
      defaultChoice: { label: string, value: CartV2SelectedOption },
      choices: { label: string, value: CartV2SelectedOption }[],
      // currentCartChoice: {label: string, value: CartV2SelectedOption}
    }[] = []


    // For each option, build a dropdown
    for (const option of catalogItem.backendOptions) {
      const correspondingCartOption = cartItem.options.find(item => item.option_id === option.systemId)
      if (!correspondingCartOption) {
        setErrorMsg(`Couldn't find option ID ${option.systemId} in cart item`)
        return null
      }
      const correspondingCatalogChoice = option.choices.find(item => item.systemId === correspondingCartOption.choice_id)
      if (!correspondingCatalogChoice) {
        setErrorMsg(`Couldn't find choice ID ${correspondingCartOption.choice_id} in catalog`)
        return null
      }

      // If edit mode, disallow edits that reduce price
      let choicesFiltered = option.choices
      if (originalQuantity && originalQuantity > 0) {
        // Filter dropdown to only contain options with same or greater price
        // Use initial data to figure this out
        const originalChoiceUpgradeSku = originalChoices[option.systemId].upgradeSku

        // Filter dropdowns
        // If upgradeSku dne, allow everything 
        // If upgradeSku exists, only allow same sku
        if (originalChoiceUpgradeSku) {
          choicesFiltered = option.choices.filter(item => item.addedCostVariantId === originalChoiceUpgradeSku)
        }
      }

      optionsDropdowns.push({
        optionName: option.displayName,
        defaultChoice: { label: correspondingCatalogChoice.displayName, value: { option_id: option.systemId, choice_id: correspondingCatalogChoice.systemId, upgrade_variant_id: correspondingCatalogChoice.addedCostVariantId } },
        choices: choicesFiltered.map(choice => ({ label: choice.displayName, value: { option_id: option.systemId, choice_id: choice.systemId, upgrade_variant_id: choice.addedCostVariantId } })),
        // currentCartChoice: {}
      })
    }




    // Calculate price of line item incl upgrade variants
    // let finalUnitPrice = cartItem.price
    let finalUnitPrice = getPriceOfVariantId(cartItem.variant_id, globalContext.selectedCollection?.catalog || [])
    if (!finalUnitPrice) {
      setErrorMsg(`Price lookup failed for item ${cartItem.variant_id}`)
      return null
    }
    for (const option of cartItem.options) {
      if (option.upgrade_variant_id) {
        const addedCost = globalContext.selectedCollection?.upgradeVariants[option.upgrade_variant_id]
        if (!addedCost) {
          // Shouldn't happen
          setErrorMsg(`Corresponding upgrade SKU not found despite being declared: ${option.upgrade_variant_id}`)
          return null
        }
        finalUnitPrice += addedCost
      }
    }



    return (
      <Box key={`product-${catalogItem.productId}-${catalogItem.variantId}`} bgColor="white" rounded="md" width="100%" textColor="black" minH="100%">
        <Stack direction={["column", "column", "row"]}>
          <AspectRatio ratio={[16 / 9, 16 / 9, 4 / 3]} w={["100%", "100%", "500px", "600px"]} >
            {catalogItem.imageSrc ?
              <Image src={catalogItem.imageSrc || ""} alt={catalogItem.variantTitle ? `${catalogItem.productTitle} - ${catalogItem.variantTitle}` : catalogItem.productTitle} objectFit='cover' roundedTopLeft="md" roundedBottomLeft={["0", "0", "md"]} roundedTopRight={["md", "md", "0"]} />
              :
              <Center w="100%" h="100%" bgColor="gray.500" roundedTopStart="md" roundedBottomStart={["none", "none", "md"]} roundedTopEnd={["md", "md", "none"]}>
                <LogoWhite width="min(8em, 25%)" />
              </Center>
            }
          </AspectRatio>
          <Flex direction={["column"]} align="start" px={4} pt={[0, 0, 4]} pb={4} textAlign="start" w="100%">
            <Box flex={1} w="100%">
              <Text fontSize="2xl" fontWeight="semibold" textAlign="start">
                {cartItem.quantity} × {catalogItem.variantTitle ? `${catalogItem.productTitle} - ${catalogItem.variantTitle}` : catalogItem.productTitle}
              </Text>
              <Stack direction="column" my={1} mt={2}>
                {unsplitVariantName &&
                  <Text>{unsplitVariantName}</Text>
                }
                {/* Display both a unit and total price if qty > 1 */}
                {cartItem.quantity > 1 && <Text>Unit price: ${finalUnitPrice.toFixed(2)}</Text>}
                <Text fontWeight="semibold">{cartItem.quantity > 1 && "Total: "}${(finalUnitPrice * cartItem.quantity).toFixed(2)}</Text>

                {/* Options dropdowns */}
                <Stack direction="column" my={1} mt={2}>

                  {/* Backend options */}
                  {optionsDropdowns.map(option => {

                    return (
                      <Box w="100%" key={`backend-option-${option.optionName}`}>
                        <Text>{option.optionName}</Text>
                        <Select
                          colorScheme="ck"
                          isClearable={false}
                          isSearchable={false}
                          options={option.choices}
                          // value={option.defaultChoice}
                          defaultValue={option.defaultChoice}
                          onChange={selection => {
                            if (selection) {
                              setOptions(cartItem, { option_id: selection.value.option_id, choice_id: selection.value.choice_id, upgrade_variant_id: selection.value.upgrade_variant_id })
                            } else {
                              // Dropdown is unclearable
                            }
                          }}
                        />
                      </Box>
                    )
                  })}
                </Stack>

              </Stack>
            </Box>
            {qtyLimit &&
              <Text fontSize="sm">This product is limited to {qtyLimit.toFixed(0)} per customer.</Text>
            }
            <Wrap mt={4} overflow="visible">

              {(!qtyLimit || qtyLimit < 1) &&
                <NumberInput
                  w="20"
                  value={cartItem.quantity}
                  defaultValue={cartItem.quantity}
                  min={originalQuantity ? originalQuantity : 0}
                  max={qtyLimit ? qtyLimit : 999}
                  onChange={(_, number) => setCartQuantity(cartItem, number)}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              }
              {!(originalQuantity && originalQuantity > 0) &&
                <Button colorScheme="red" variant="outline" w="auto" mt={3}
                  onClick={() => removeFromCart(cartItem)}
                >
                  Remove From Cart
                </Button>
              }
            </Wrap>
          </Flex>
        </Stack>
      </Box>
    )

  }
