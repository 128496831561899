import React, { useEffect, useMemo, useState } from "react"
import {
  Text,
  Button,
  VStack,
} from "@chakra-ui/react"
import { useGlobalContext } from "../DataManagement/GlobalContextManager"
import { BasePageTemplate } from "./BasePageTemplate"
import { useNavigate } from "react-router-dom"

import { MdRemoveShoppingCart } from 'react-icons/md';
import { useCartManagerV2 } from "../DataManagement/CartManagerV2"
import { findMatchingCatalogItemByVariantId } from "../DataManagement/CartManagerV2Utils"
import { CatalogProductType } from "../DataManagement/Types"

export const RaffleSoldOutView: React.FC<{}> = () => {

  const globalContext = useGlobalContext()
  const cartManagerV2 = useCartManagerV2()
  const navigate = useNavigate()

  // SubID and similar must be cleared here to avoid an inf loop
  const soldOutItems: CatalogProductType[] | undefined = useMemo(() => {
    return cartManagerV2.cartItemsExceedingAvailableStock.map(item => {
      return findMatchingCatalogItemByVariantId(item.variant_id, globalContext.selectedCollection?.catalog!)!
    })
  }, [cartManagerV2.cartItemsExceedingAvailableStock])
  const [tmpSoldOutDisplay] = useState<CatalogProductType[] | null>(soldOutItems)

  useEffect(() => {
    if (globalContext.subscriptionId) {
      globalContext.setSubscriptionId("")
      cartManagerV2.deleteSavedCart()
    }
  }, [])

  return (
    <BasePageTemplate maxW="container.lg" headerText="Sold Out" headerIcon={<MdRemoveShoppingCart size="6em" />}>
      <VStack mb={4}>
        {(tmpSoldOutDisplay && tmpSoldOutDisplay.length > 0) ?
          <>
            <Text mt={2}>Unfortuantely, the following items have sold out:</Text>
            {
              tmpSoldOutDisplay.map(item => {
                return (<Text key={item.variantId}>{item.productTitle}</Text>)
              })
            }
          </>
          :
          <Text>Unfortunately, item(s) in your cart have sold out.</Text>
        }
      </VStack>
      <Button colorScheme="whiteAlpha" onClick={() => navigate("/")}>Main Menu</Button>
    </BasePageTemplate>
  )
}