import React, { useEffect, useState } from "react"
import {
  Button,
  Container,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react"
import { useGlobalContext } from "../DataManagement/GlobalContextManager"
import { CartManagerV2, useCartManagerV2 } from "../DataManagement/CartManagerV2"
import { Navigate, useNavigate } from "react-router-dom"
import axios from "axios"
import { useMutation } from "react-query"
import { CreateSubscriptionBody, createSubscription } from "../../api/backend"
import { BasePageTemplate } from "./BasePageTemplate"
import { ErrorType, ErrorTypes } from "../DataManagement/Types"
import { ErrorScreen } from "../CommonComponents/ErrorScreen"
import { convertCartToBackendForm } from "../DataManagement/CartManagerV2Utils"


export const JoinRaffleView: React.FC<{}> = () => {

  const globalContext = useGlobalContext()
  const cartManagerV2 = useCartManagerV2()
  const navigate = useNavigate()

  console.log(globalContext)


  const [errorMsg, setErrorMsg] = useState<ErrorType | null>(null)


  const everythingLoaded = !globalContext.isLoading && !cartManagerV2.isLoading
  const everythingNotError = !globalContext.isError && !cartManagerV2.isError

  // LONG-TERM: Check validity (i.e. this cart doesn't contain contents from other releases)
  // Shouldn't be necessary rn since cart contents are cleared on navigate to /, and cart contents are only saved within raffling and loaded from within it


  // Mutation to create a subscription
  const createSubscriptionIdMutation = useMutation((postSubscriptionBody: CreateSubscriptionBody) => createSubscription(postSubscriptionBody), {
    onError: error => {
      // If creating a subscr fails
      console.error(error);
      // If axios error, can fetch a useful message 
      if (axios.isAxiosError(error)) {
        setErrorMsg({
          type: ErrorTypes.GenericFailedCreateSubAxios,
          message: error.message
        })
      }
      // Otherwise just need to pray that stringify yields a meaningful result
      else {
        setErrorMsg({
          type: ErrorTypes.GenericFailedCreateSub,
          message: JSON.stringify(error)
        })
      }
    },
    onSuccess: data => {
      if (!data.sub_id || data.sub_id === "") {
        // This would still be a fail or unexpected result
        setErrorMsg({
          type: ErrorTypes.NoSubIdReturned,
          message: ""
        })
      } else {
        // Set subscription ID
        globalContext.setSubscriptionId(data.sub_id)
        // Save cart so it persists in raffling scene
        cartManagerV2.saveCart()
        // Redir to raffling page is handled by useEffect earlier
      }
    },
    onSettled: () => {
    },
    retry: (failureCount, error) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 429) {
          return true
        } 
      }
      return failureCount < 3
    }
  })


  // Initiate the subscription creation just once
  useEffect(() => {
    if (
      // Everything loaded
      everythingLoaded &&
      everythingNotError &&
      // Cart is existent
      cartManagerV2.cart.length > 0 &&
      // Cart does not contain OOS items
      cartManagerV2.cartItemsExceedingAvailableStock.length === 0 &&
      // Haven't started creating one just yet
      createSubscriptionIdMutation.isIdle &&
      // Sub ID doesn't already exist
      !globalContext.subscriptionId &&
      // GB is open time-wise
      (globalContext.collectionTimeState === "PENDING_RAFFLE" || globalContext.collectionTimeState === "RAFFLING")
    ) {
      // Convert cart and post
      const convertedCart = convertCartToBackendForm(cartManagerV2.cart)
      const postSubscriptionBody: CreateSubscriptionBody = {
        release_id: globalContext.selectedCollection?.releaseId!,
        secret: globalContext.selectedCollection?.secret!,
        cart: convertedCart.cart!,
        options: convertedCart.options!
      }
      createSubscriptionIdMutation.mutate(postSubscriptionBody)
    }
  }, [globalContext, cartManagerV2, createSubscriptionIdMutation, everythingLoaded, everythingNotError])



  if (errorMsg) {
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Joining Raffle"}>
        <ErrorScreen error={errorMsg} />
      </BasePageTemplate>
    )
  } else if (globalContext.isError) {
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Joining Raffle"}>
        <ErrorScreen error={({ type: ErrorTypes.GlobalContextError, message: "" })} />
      </BasePageTemplate>
    )
  } else if (cartManagerV2.isError) {
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Joining Raffle"}>
        <ErrorScreen error={({ type: ErrorTypes.CartManagerError, message: cartManagerV2.statusMsg || "" })} />
      </BasePageTemplate>
    )

  }
  else if (!globalContext.isLoading && !globalContext.selectedCollection) {
    console.error("Deadlock situation encountered on join")
    // Resolve deadlocks in case context has fully loaded but collection is null
    cartManagerV2.deleteSavedCart()
    return <Navigate to="/" />
  }
  else if (cartManagerV2.isLoading || globalContext.isLoading) {
    // Await load
    return (
      <BasePageTemplate maxW="container.lg" headerText={""}>
        {/* <Text fontSize="lg" fontWeight="semibold">Submitting your cart</Text>
        <Text mt={2}>Just a moment...</Text> */}
        {/* <Text mb={8}>Subscription ID: {globalContext.subscriptionId}</Text> */}
        <Container mt={8} pb={2}>
          <VStack w="100%">
            <Spinner />
          </VStack>
        </Container>
      </BasePageTemplate>
    )
  }
  // If sub id exists, navigate out
  else if (globalContext.subscriptionId) {
    return <Navigate to="/raffle" />
  }
  // If collection is null, nav out
  else if (globalContext.selectedCollection === null) {
    return <Navigate to="/" />
  }
  // If cart is empty, nav out (can't submit)
  else if (cartManagerV2.cart.length <= 0) {
    return <Navigate to="/cart" />
  }
  // If col isn't even open yet, nav out
  else if (globalContext.collectionTimeState === "BEFORE_LAUNCH") {
    return <Navigate to="/" />
  }
  // If gb has ended, display an ended screen
  else if (globalContext.collectionTimeState === "CLOSED") {
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Joining Raffle"}>
        <Text>The sale has ended.</Text>
        <Button onClick={() => navigate("/")} colorScheme="whiteAlpha">Main Menu</Button>
      </BasePageTemplate>
    )
  }
  // Otherwise just show waiting screen
  else {
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Joining Raffle"}>
        <Text fontSize="lg" fontWeight="semibold">Submitting your cart</Text>
        <Text mt={2}>Just a moment...</Text>
        {/* <Text mb={8}>Subscription ID: {globalContext.subscriptionId}</Text> */}
        <Container mt={8} pb={2}>
          <VStack w="100%">
            <Spinner />
          </VStack>
        </Container>
      </BasePageTemplate>
    )
  }

}