import { DateTime } from "luxon"


// ========================= 
// Product type consts
// ========================= 

export const ProductTypes = {
  UnsplitVariants: "UNSPLIT_VARIANTS",
  SplitVariants: "SPLIT_VARIANTS",
  Standalone: "STANDALONE"
}

export type ProductVariantsType = "UNSPLIT_VARIANTS" | "SPLIT_VARIANTS" | "STANDALONE"


// ========================= 
// Catalog types (what's available, not what's in the cart)
// ========================= 

// A single choice for an option
export type ChoiceType = {
  displayName: string,
  systemId: number,
  addedCostVariantId: string | null
}


// The base option type (also joined by SelectedOptionTrackerType)
export type OptionType = {
  displayName: string,
  systemId: number
}


// Catalog option type that includes all choices
export type ListedOptionType = OptionType & {
  choices: ChoiceType[]
}


// UnsplitChoice type 
export type UnsplitVariantType = {
  displayName: string,
  systemName: string,
  variantId: string,
  imageSrc: string | null,
  imageSrcTransformed: string | null,
  price: number,
  qtyInStock: number,
}


// The base product type
export type CatalogProductType = {
  // Product ID - either the one that contains the variant, or the standalone for unsplit variants
  productId: string,

  // Product title
  productTitle: string,
  // Variant title if unique (i.e. length of variants > 1)
  variantTitle: string | null,

  // Variant image
  imageSrc: string | null,
  // Variant image transformed
  imageSrcTransformed: string | null,

  // Backend Options
  backendOptions: ListedOptionType[],

  // Unsplit options
  unsplitVariants: UnsplitVariantType[],

  // A variant ID that may or may not exist
  // Variant ID possibilities 
  // - unsplitVariants length > 0 - variants are treated as options rather than dropdowns
  //   - In this case, variant ID and title are null
  // - Otherwise, variant ID is set
  variantId: string | null,

  // Variant price - query returns a string so must be parsed
  // Can be set to null for unsplit variants where each variant option carries its own
  price: number | null,

  // Quantity remaining in stock
  // Can be left null if unsplit variants (each of those handles their own)
  qtyInStock: number | null,

  // Hypemain tag - Should appear first
  hypemain: boolean

  // Type of product - easy lookup
  productType: ProductVariantsType
}



// ========================= 
// Cart Management Types (not catalog, see above)
// ========================= 

// In-Cart Line Item
export type CartV2LineItem = {
  // The two fields used on backend return
  variant_id: string,
  quantity: number,

  // The necessary data to reference the catalog + split options later
  product_id: string,

  // Price - handy to have for total cart cost calculations
  // price: number

  // Per-line-item options management (must be pulled out for pushing to backend)
  options: CartV2SelectedOption[]
}

// Cart Line Item's selected options
export type CartV2SelectedOption = {
  option_id: number,
  choice_id: number,
  // NOTE: Break apart upgrade variant IDs to their own line item when converting for push to backend
  upgrade_variant_id: string | null
}

// Option type used by CatalogEntry to manage what is picked for each option dropdown
export type SelectedOptionTrackerType = OptionType & {
  selectedChoice: ChoiceType | null
}



// ========================= 
// Lookup tables
// ========================= 

// For declaring quantity limits
// Index = Product ID, Value = Quantity Limit
// If the index does not exist, there is no qty limit for the product
export interface QuantityLimitLookupTable {
  [index: string]: number;
}


// For listing cost-added upgrades and their prices
// Index = Variant ID, Value = Price
export interface UpgradeVariantLookupTable {
  [index: string]: number;
}



// ========================= 
// Local selected collection data (provided by useCollection)
// ========================= 

// Merges backend-provided release details and transformed Shopify collection data into a single holistic reference
export type SelectedCollection = {
  // Collection ID (Shopify collection ID)
  id: string,
  // Release ID (Backend-generated)
  releaseId: string,
  // Collection title
  title: string,

  // Quantity caps
  qtyLimits: QuantityLimitLookupTable,

  // Quantity of hypeMain product left
  totalHypemainQty: number,

  // Upgrade variants
  upgradeVariants: UpgradeVariantLookupTable,

  // Collection image
  imageSrc: string | null,
  // Collection image transformed
  imageSrcTransformed: string | null,

  // Time controls
  releaseTime: DateTime,
  raffleStartTime: DateTime,
  closingTime: DateTime,

  // Secret string
  secret: string,
  
  // Collection catalog
  catalog: CatalogProductType[]
}



// ========================= 
// Backend-provided options (exactly as structured by backend)
// ========================= 

// Product option choices from backend
export type BackendOptionChoice = {
  // ID of choice itself
  id: number,
  // Display string
  choice_label: string,
  // Upgrade variant ID if applicable, or blank otherwise ("")
  upgrade_variant_id: string
}

// Product Options from backend
export type BackendProductOption = {
  // ID of option itself
  id: number,
  // Product the option is bound to (shopify ID)
  product_id: string,
  // Display string
  option_label: string,
  // The choices for the option
  option_choices: BackendOptionChoice[]
}



// ========================= 
// Backend-provided collection metadata types
// ========================= 

export const CollectionStatuses = {
  // Not live yet
  SCHEDULED: 0,
  // Open and waiting for release subscriptions to be created
  OPEN: 1,
  // All main units are sold out, release is no longer listed as active,
  // and no one can join the queue. Edits are open if allowed
  RAFFLE_ENDED: 2,
  // Release is closed and edits are not open
  CLOSED: 3
}


// Collection Metadata from backend
export type CollectionMetadata = {
  // System ID and name
  id: string,
  name: string,
  // Secret
  secret: string,
  // Status code
  status: number,
  // Release and closing times
  release_time: string,
  closing_time: string,
  // Applicable options
  product_options: BackendProductOption[]
}



// ========================= 
// Error types
// ========================= 

export const ErrorTypes = {
  // Specific errors don't use the msg field
  MissingSubId: "MISSING_SUB_ID",
  NoSubIdReturned: "NO_SUB_ID_RETURNED",

  // Context errors
  GlobalContextError: "GLOBAL_CONTEXT_ERROR",
  CartManagerError: "CART_MANAGER_ERROR",

  // Cart edit error
  CartEditError: "CART_EDIT_ERROR",

  // Order edit errors
  OrderEditFetchError: "ORDER_EDIT_FETCH_ERROR",

  // Generics use message field
  GenericFailedCreateSubAxios: "GENERIC_FAILED_CREATE_SUB_AXIOS",
  GenericFailedCreateSub: "GENERIC_FAILED_CREATE_SUB",
  GenericAxios: "GENERIC_AXIOS",
  Generic: "GENERIC",
}

export type ErrorType = {
  type: string,
  message: string
}



// =========================
// Cart manager function return types
// =========================

// Cart modifier fns return type
export type CartEditFnReturnType = {
  success: boolean,
  status: string
}

// Converter from frontend to backend return type
export type CartConverterReturnType = {
  cart: BackendPostCart | null,
  options: BackendPostOptions | null,
  error: string | null
}



// =========================
// Backend Post Types (for submitting data to backend)
// =========================

// Backend wants cart in this form
// List of LineItems that should go into the cart
// Format: {"line_items" : [ {"variant_id": <variant_id>, "quantity": <qty>} ] }
// This should include any upgrade items necessary for the options selected
export type BackendPostLineItem = {
  variant_id: string,
  quantity: number,
}

export type BackendPostCart = {
  line_items: BackendPostLineItem[]
}


// Backend wants options in this form
// # Options associated with the items in the cart
//     # Format: {"options": [
//     #   {"product_id: <product_id>,
//     #    "selected_options": [
//     #       {
//     #           option_id: <product_option_id>,
//     #           choice_id: <selected_item_id>,
//     #       },
//     #       etc.
//     #     ]
//     #    }
//     # ]}
export type BackendPostSelectedOptions = {
  option_id: number,
  choice_id: number
}

export type BackendPostOptionsEntry = {
  product_id: string,
  selected_options: BackendPostSelectedOptions[]
}

export type BackendPostOptions = {
  options: BackendPostOptionsEntry[]
}



// =========================
// Global Context
// =========================

export type CollectionTimeStates = "BEFORE_LAUNCH" | "PENDING_RAFFLE" | "RAFFLING" | "CLOSED" | null
