import React, { useEffect, useMemo, useState } from "react"
import {
  Text,
  Button,
} from "@chakra-ui/react"
import { useGlobalContext } from "../DataManagement/GlobalContextManager"
import { BasePageTemplate } from "./BasePageTemplate"
import { Navigate, useNavigate } from "react-router-dom"

import { useCartManagerV2 } from "../DataManagement/CartManagerV2"
import axios from "axios"
import { convertCartToBackendForm } from "../DataManagement/CartManagerV2Utils"
import useEditOrderQuery from "../../hooks/useEditOrderQuery"
import { ErrorScreen } from "../CommonComponents/ErrorScreen"
import { ErrorTypes } from "../DataManagement/Types"

export const EditOrderSubmitView: React.FC<{}> = () => {

  const globalContext = useGlobalContext()
  const cartManagerV2 = useCartManagerV2()
  const navigate = useNavigate()

  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  const [started, setStarted] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)

  const dataToSubmit = useMemo(() => convertCartToBackendForm(cartManagerV2.cart), [cartManagerV2.cart])
  if (dataToSubmit.error) {
    setErrorMsg(dataToSubmit.error)
  }

  // Mutation to change addrs automatically

  const editOrderQuery = useEditOrderQuery({
    inputs: {
      subscription_id: globalContext.orderEditDetails.subId!,
      order_number: globalContext.orderEditDetails.orderNum!,
      email: globalContext.orderEditDetails.email!,
      selected_options: dataToSubmit.options!,
      cart: dataToSubmit.cart!
    },
    onSuccess: (data) => {
      console.log("reported success")
      if (data.complete && data.success) {
        console.log("true success")
        setSuccess(true)
      } else {
        setErrorMsg(
          `Failed to edit order - please contact CannonKeys if the problem persists. Details: Backend returned success false for complete true for order ID ${globalContext.orderEditDetails.orderNum?.toString() || "undef"}`
        );
      }
    },
    onError: (error) => {
      console.error("Failed to edit the order")
      console.error(error)
      let details = ""
      if (axios.isAxiosError(error)) {
        details = `${error.message}`
      } else {
        details = JSON.stringify(error)
      }
      setErrorMsg(`Failed to edit order - please contact CannonKeys if the problem persists. Details: ${details}`);
    },
  });

  useEffect(() => {
    if (!started && globalContext.orderEditDetails.email && globalContext.orderEditDetails.orderNum && globalContext.orderEditDetails.subId && !dataToSubmit.error) {
      console.log("starting")
      setStarted(true)
      editOrderQuery.mutate()
    }
  }, [started, globalContext.orderEditDetails, dataToSubmit])



  if (cartManagerV2.isError) {
    // Error screen
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Raffling"}>
        <ErrorScreen error={({ type: ErrorTypes.CartManagerError, message: cartManagerV2.statusMsg || "" })} />
      </BasePageTemplate>
    )
  }
  else if (globalContext.isError) {
    // Error
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Raffling"}>
        <ErrorScreen error={({ type: ErrorTypes.GlobalContextError, message: "" })} />
      </BasePageTemplate>
    )
  } else if (errorMsg) {
    // Screen-specific error
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Raffling"}>
        <ErrorScreen error={({ type: ErrorTypes.Generic, message: errorMsg })} />
      </BasePageTemplate>
    )
  } else if (cartManagerV2.isLoading || globalContext.isLoading) {
    // Loading screen
    return (
      <BasePageTemplate maxW="container.sm" headerText="Edit Order">

        <Text pb={3}>Loading...</Text>

      </BasePageTemplate>
    )
  } else if (cartManagerV2.cart.length <= 0) {
    // Cart empty, nothing to submit
    return <Navigate to="/edit/lookup" />
  } else if (!globalContext.orderEditDetails.email || !globalContext.orderEditDetails.orderNum || !globalContext.orderEditDetails.subId) {
    // Missing edit info
    return <Navigate to="/edit/lookup" />
  } else if (success) {
    // Display success screen
    return (
      <BasePageTemplate maxW="container.sm" headerText="Edit Order">

        <Text pb={3}>Success</Text>

        <Button onClick={() => navigate("/")} colorScheme="whiteAlpha">
          Back to Main Menu
        </Button>

      </BasePageTemplate>
    )
  } else {
    // Still waiting
    return (
      <BasePageTemplate maxW="container.sm" headerText="Edit Order">

        <Text pb={3}>Submitting your edit...</Text>

      </BasePageTemplate>
    )
  }

}