import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useMutation } from 'react-query';
import axios from "axios";
import { useGlobalContext } from "../DataManagement/GlobalContextManager";
import { deleteSubscription } from "../../api/backend";
import { ErrorType, ErrorTypes } from "../DataManagement/Types";
import { useCartManagerV2 } from "../DataManagement/CartManagerV2";


export const LeaveRaffleModal: React.FC<{
  isOpen: boolean,
  onClose: () => void,
  onError: (errorMsg: ErrorType) => void
}> = ({
  isOpen,
  onClose,
  onError
}) => {

    const globalContext = useGlobalContext()
    const cartManagerV2 = useCartManagerV2()
    const navigate = useNavigate()

    // Mutation to create a subscription
    const leaveRaffleMutation = useMutation(() => deleteSubscription(globalContext.subscriptionId!), {
      onError: error => {
        // If creating a subscr fails
        console.error(error);
        // If axios error, can fetch a useful message 
        if (axios.isAxiosError(error)) {
          onError({
            type: ErrorTypes.Generic,
            message: `Failed to leave the raffle - ${error.message}`
          })
        }
        // Otherwise just need to pray that stringify yields a meaningful result
        else {
          onError({
            type: ErrorTypes.Generic,
            message: `Failed to leave the raffle - ${JSON.stringify(error)}`
          })
        }
      },
      onSuccess: data => {
        globalContext.setSubscriptionId(null)
        cartManagerV2.deleteSavedCart()
        // Redir to cart
        navigate("/cart")
      },
      onSettled: () => {
      }
    })



    return (
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Leave Raffle</ModalHeader>
          <ModalBody>
            <Text mb={4}>Are you sure you want to leave the raffle?</Text>
            <Text mt={4}>You won't be able to re-join to your original spot - re-joining will be treated as an entirely new entry.</Text>
          </ModalBody>

          <ModalFooter>
            {!leaveRaffleMutation.isLoading &&
              <Button variant="ghost" mr={3} onClick={onClose}>
                Cancel
              </Button>
            }
            <Button
              colorScheme="red"
              onClick={() => leaveRaffleMutation.mutate()}
              isLoading={leaveRaffleMutation.isLoading}
            >Leave Raffle</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );

  };
