import axios from "axios";
import { BackendPostCart, BackendPostOptions } from "../components/DataManagement/Types";


// Endpoint URLs

const ROOT_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT ?? ""
const GET_RELEASES = `${ROOT_ENDPOINT}/api/v1/public/hype/releases`;
const CREATE_SUBSCRIPTION = `${ROOT_ENDPOINT}/api/v1/public/hype/subscriptions`;
const GET_SUBSCRIPTION = `${ROOT_ENDPOINT}/api/v1/public/hype/subscription`;
const LOOKUP_ORDER = `${ROOT_ENDPOINT}/api/v1/public/hype/order`;
const EDIT_ORDER = `${ROOT_ENDPOINT}/api/v1/public/hype/order/edit`;


// Types that define the structure of how the data is returned from backend
// Shouldn't be used as-is within frontend data management

export type BackendChoice = {
  id: number,
  choice_label: string,
  upgrade_variant_id: string
}

export type BackendGetOption = {
  id: number,
  shopify_product_id: string,
  option_label: string,
  option_choices: BackendChoice[]
}

export type BackendRelease = {
  id: string,
  name: string,
  secret: string,
  status: number,
  release_time: string,
  wait_period_min: number,
  closing_time: string,
  allow_edits: boolean,
  collection_title: string,
  product_options: BackendGetOption[],
}

export type GetReleasesResponseType = {
  releases: BackendRelease[]
};

export type CreateSubscriptionBody = {
  release_id: string,
  secret: string,
  cart: BackendPostCart,
  options: BackendPostOptions
}

export type CreateSubscriptionResponse = {
  sub_id: string | null | undefined,
  error: string | null | undefined,
}


export type GetSubscriptionResponse = {
  success: boolean,
  checkoutUrl: string
}


export type LookupOrderBody = {
  email: string,
  order_number: number,
  subscription_id: string
}

export type LookupOrderCartEntry = {
  quantity: number
}

export interface LookupOrderCartData {
  [variantId: string]: LookupOrderCartEntry;
}

export type LookupOrderResponse = {
  // Shopify order ID
  order_id: string | null | undefined,
  // The collection handle that can be used to query shopify
  collection_title: string | null | undefined,
  // Cart data
  cart: BackendPostCart | null | undefined,
  // Selected options
  selected_options: BackendPostOptions | null | undefined,
  error: string | null | undefined,
}

export type EditOrderBody = {
  subscription_id: string,
  order_number: number,
  email: string,
  selected_options: BackendPostOptions,
  cart: BackendPostCart
}

export type EditOrderResponse = {
  error: string | null | undefined,
  job_id: string | null | undefined
}

export type GetEditOrderStatusResponse = {
  complete: boolean,
  success: boolean | null | undefined
}


// The actual networking functions themselves


// Get releases

const getReleases = async () => {
  const { data } = await axios.get<GetReleasesResponseType>(
    GET_RELEASES,
  );
  return data;
};


// Create subscription

const createSubscription = async (body: CreateSubscriptionBody) => {
  const { data } = await axios.post<CreateSubscriptionResponse>(
    CREATE_SUBSCRIPTION,
    body
  );
  return data;
}


// Get subscription status
const checkSubscription = async (subscriptionId: string) => {
  const { data } = await axios.get<GetSubscriptionResponse>(
    `${GET_SUBSCRIPTION}/${subscriptionId}`,
  );
  return data;
};


// Delete subscription
const deleteSubscription = async (subscriptionId: string) => {
  const { data } = await axios.delete<CreateSubscriptionResponse>(
    `${GET_SUBSCRIPTION}/${subscriptionId}`,
  );
  return data;
}


// Lookup order for edit
const lookupOrder = async (body: LookupOrderBody) => {
  const { data } = await axios.post<LookupOrderResponse>(
    LOOKUP_ORDER,
    body
  );
  return data;
}


// Lookup order for edit
const editOrder = async (body: EditOrderBody) => {
  const { data } = await axios.post<EditOrderResponse>(
    EDIT_ORDER,
    body
  );
  return data;
}

// Lookup order edit status
const getEditOrderStatus = async (job_id: string) => {
  const { data } = await axios.get<GetEditOrderStatusResponse>(
    EDIT_ORDER,
    {params: {job_id: job_id}}
  );
  return data;
}



export {
  getReleases,
  createSubscription,
  checkSubscription,
  deleteSubscription,
  lookupOrder,
  editOrder,
  getEditOrderStatus,
};
