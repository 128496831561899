import React from "react"
import {
  Box,
  Heading,
  Container,
  Stack,
  HStack,
} from "@chakra-ui/react"
import { ReactComponent as LogoWhite } from '../../assets/LogoWhite-NoText.svg'

export const BasePageTemplate: React.FC<{
  maxW: string,
  headerText: string,
  children: React.ReactNode,
  bgImage?: string | undefined
}> = ({
  maxW,
  headerText,
  children,
  bgImage
}) => {

    return (
      <Box w="100%" minH="100vh" bgColor="ckNavy" textColor="white" textAlign="center" alignItems="center" justifyItems="center" justifyContent="center" alignContent="center" pt={8} 
      bgImage={bgImage} bgSize="cover" bgPosition="center">
        <Stack direction="column" alignItems="center" justifyItems="center" justifyContent="center" alignContent="center">
          <LogoWhite width="6em" height="6em" />
          <HStack alignItems="top" >
            <Heading as="h1" fontSize="32px" fontWeight="600">{headerText}</Heading>
          </HStack>
        </Stack>

        <Container maxW={maxW} mt={12}>
          {children}
        </Container>

      </Box>
    )
  }