import React, { useEffect } from "react"
import {
  Text,
  Button,
} from "@chakra-ui/react"
import { BasePageTemplate } from "./BasePageTemplate"
import { useNavigate } from "react-router-dom"

import { MdTimerOff } from 'react-icons/md';
import { useCartManagerV2 } from "../DataManagement/CartManagerV2";
import { useGlobalContext } from "../DataManagement/GlobalContextManager";

export const RaffleClosedView: React.FC<{}> = () => {

  const globalContext = useGlobalContext()
  const cartManagerV2 = useCartManagerV2()
  const navigate = useNavigate()

  useEffect(() => {
    globalContext.setSubscriptionId("")
    cartManagerV2.deleteSavedCart()
  }, [])

  return (
    <BasePageTemplate maxW="container.lg" headerText="Sale Ended" headerIcon={<MdTimerOff size="6em" />}>
      <Text mt={2} mb={8}>The project is no longer available for sale.</Text>
      <Button colorScheme="whiteAlpha" onClick={() => navigate("/")}>Main Menu</Button>
    </BasePageTemplate>
  )
}