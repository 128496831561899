import React, { useState } from "react"
import { findCartItemsExceedingStock, findItemInCart, getTotalCartPrice, getTotalItemQty, verifyQuantityCapForProduct } from "./CartManagerV2Utils";
import { useGlobalContext } from "./GlobalContextManager";
import { clearStoredCartV2, getStoredCartV2, setStoredCartV2 } from "./LocalStorageManager";
import { CartEditFnReturnType, CartV2LineItem, CartV2SelectedOption } from "./Types";


// ========================= //
// Cart Manager Context


// Cart manager context 

type CartManagerV2ContextType = {
  // The current cart state
  cart: CartV2LineItem[],

  // Derived total line items and price for easier use
  totalItems: number,
  totalPrice: number,

  // Whether or not the cart manager is ready for use
  isLoading: boolean,
  isError: boolean,
  statusMsg: string | null,

  // Cart manipulation functions
  addToCart: (input: CartV2LineItem) => CartEditFnReturnType,
  setQuantityForItem: (input: CartV2LineItem, quantity: number) => CartEditFnReturnType,
  setChoiceForItem: (input: CartV2LineItem, choice: CartV2SelectedOption) => CartEditFnReturnType,
  clearCart: () => CartEditFnReturnType,
  importCart: (input: CartV2LineItem[]) => CartEditFnReturnType,

  // Cart storage functions (localstorage)
  saveCart: () => CartEditFnReturnType,
  deleteSavedCart: () => CartEditFnReturnType,
  loadSavedCart: () => CartEditFnReturnType,

  // A list of items in cart whose quantities exceed available stock reported by Shopify
  cartItemsExceedingAvailableStock: CartV2LineItem[],
}


// The cart context and functions to use it
const CartManagerV2Context = React.createContext<CartManagerV2ContextType>({
  cart: [],

  totalItems: 0,
  totalPrice: 0,

  isLoading: true,
  isError: false,
  statusMsg: null,

  addToCart: (_) => { return { success: true, status: "" } },
  setQuantityForItem: (_, __) => { return { success: true, status: "" } },
  setChoiceForItem: (_, __) => { return { success: true, status: "" } },
  clearCart: () => { return { success: true, status: "" } },
  saveCart: () => { return { success: true, status: "" } },
  deleteSavedCart: () => { return { success: true, status: "" } },
  loadSavedCart: () => { return { success: true, status: "" } },
  importCart: (_) => { return { success: true, status: "" } },

  cartItemsExceedingAvailableStock: [],
})

// Easier use of the context
export const useCartManagerV2 = () => { return React.useContext(CartManagerV2Context) }



// ========================= //
// Cart Manager itself


export const CartManagerV2: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {

  const globalContext = useGlobalContext()
  const qtyLimits = globalContext.selectedCollection?.qtyLimits || {}

  const [cartState, setCartState] = useState<CartV2LineItem[]>([])
  const [errorMsg, setErrorMsg] = useState<string | null>(null)


  // If globalContext is not ready under any circumstance, return early before it's accessed
  // i.e. CartManager should not be usable if globalContext cannot provide a catalog to reference
  // Start by building a temporary context value
  let contextValue: CartManagerV2ContextType = {
    cart: cartState,

    totalItems: 0,
    totalPrice: 0,

    isLoading: false,
    isError: false,
    statusMsg: null,

    addToCart: (_) => { return { success: false, status: "CartManager function accessed while not ready" } },
    setQuantityForItem: (_, __) => { return { success: false, status: "CartManager function accessed while not ready" } },
    setChoiceForItem: (_, __) => { return { success: false, status: "CartManager function accessed while not ready" } },
    clearCart: () => { return { success: false, status: "CartManager function accessed while not ready" } },
    saveCart: () => { return { success: false, status: "CartManager function accessed while not ready" } },
    deleteSavedCart: () => { return { success: false, status: "CartManager function accessed while not ready" } },
    loadSavedCart: () => { return { success: false, status: "CartManager function accessed while not ready" } },
    importCart: (_) => { return { success: false, status: "CartManager function accessed while not ready" } },

    cartItemsExceedingAvailableStock: [],
  }
  // If global context (and its catalog) are not ready, return null
  if (globalContext.isLoading) {
    contextValue.isLoading = true
    contextValue.isError = false
    contextValue.statusMsg = "Waiting for GlobalContext to finish loading"
  }
  // Otherwise if global context has errored out, don't do anything
  else if (globalContext.isError) {
    contextValue.isLoading = false
    contextValue.isError = true
    contextValue.statusMsg = "GlobalContext reports error - cannot continue"
  }
  // Otherwise if globalContext reports no selected collection, return not ready
  else if (!globalContext.selectedCollection) {
    contextValue.isLoading = true
    contextValue.isError = false
    contextValue.statusMsg = "Awaiting GlobalContext to select a collection"
  }
  // If errorMsg is set, return that
  else if (errorMsg) {
    contextValue.isLoading = false
    contextValue.isError = true
    contextValue.statusMsg = errorMsg
  }
  // If any of the above cases hold true, return early
  if (contextValue.isLoading || contextValue.isError) {
    return (
      <CartManagerV2Context.Provider value={contextValue}>
        {children}
      </CartManagerV2Context.Provider>
    )
  }


  // If here, globalContext is ready and so is cartManager
  // Declare the functions and calculate the data to return

  // Add item to cart
  const addToCart: (inputItem: CartV2LineItem) => CartEditFnReturnType = (inputItem) => {
    // Shallow copy the cart for editing
    // let newCart = state.cart.slice()
    // NOTE TO SELF: This does NOT clone the objects inside as a deep clone
    let newCart = cartState.map(a => ({ ...a }))


    // Find the item index for identical products if exists already
    const itemIndex = findItemInCart(newCart, inputItem)

    // If item is within the cart, increment the qty
    if (itemIndex) {
      console.log(`Cart state edit: Incrementing quantity for existing line item from ${itemIndex.quantity} or ${newCart[itemIndex.index].quantity} by ${inputItem.quantity}`)
      const newQty = newCart[itemIndex.index].quantity + inputItem.quantity
      console.log(newQty)
      newCart[itemIndex.index].quantity = newQty
    }
    // Otherwise, push an entirely new item to cart
    else {

      // NOTE: As of right now, only one set of backend options are supported per product ID
      // Deny adding to cart if same prod ID exists with diff backend options
      // LONG-TERM: Remove if backend ends up supporting diff options for single product ID
      const sameProductIds = newCart.filter(item => item.product_id === inputItem.product_id)
      for (const sameProductIdItem of sameProductIds) {
        for (const optionA of sameProductIdItem.options) {
          // Find a corresponding option in input item
          const correspondingOptionB = inputItem.options.find(optionB => (optionB.option_id === optionA.option_id && optionB.choice_id === optionA.choice_id))
          if (!correspondingOptionB) {
            console.log(`Couldn't find same option for ${optionA.option_id.toString()} with choice ${optionA.choice_id.toString()}`)
            return {
              success: false,
              status: "Adding two of the same product to cart with differing sets of options is currently not supported."
            }
          }
        }
      }
      // At this point, check has passed

      console.log("Cart state edit: Adding new line item")
      newCart.push(inputItem)
    }

    // Sanity check that the new total qty for the added product does not exceed quantity limits
    // const newTotalCartQtyForItem = totalQtyOfProductInCart(newCart, input.item.productId)
    const qtyVerificationReturn = verifyQuantityCapForProduct(newCart, qtyLimits, inputItem.product_id)
    console.log(qtyVerificationReturn)
    if (!qtyVerificationReturn) {
      console.log("Qty cap would be exceeded!")
      console.log("Current cart:")
      console.log(cartState)
      console.log("Qty limits:")
      console.log(qtyLimits)
      return {
        success: false,
        status: "Cannot add item to cart: Unit cap for item exceeded."
      }
    }

    // Otherwise, sanity check passes
    console.log("Adding item to cart")
    console.log("New cart:")
    console.log(newCart)
    setCartState(newCart)
    return {
      success: true,
      status: "Item added to cart."
    }
  }

  // Set quantity for an item in the cart
  const setQuantityForItem: (inputItem: CartV2LineItem, quantity: number) => CartEditFnReturnType = (inputItem, quantity) => {
    // Shallow copy the cart for editing
    console.log("Cart state edit: Removing an item")
    let newCart = cartState.map(a => ({ ...a }))

    // Find the item index for the product to remove
    const itemIndex = findItemInCart(newCart, inputItem)

    // If item does not exist in cart, fail
    if (!itemIndex) {
      return {
        success: false,
        status: "Item does not exist in cart to be removed."
      }
    }

    // Calculate the new quantity
    const newQty = newCart[itemIndex.index].quantity = quantity

    // If the new quantity is still positive, adjust qty and return
    if (newQty > 0) {
      newCart[itemIndex.index].quantity = newQty
      setCartState(newCart)
      return {
        success: true,
        status: "Item quantity adjusted."
      }
    }
    // Otherwise, remove that item from the cart entirely
    else {
      newCart.splice(itemIndex.index, 1)
      setCartState(newCart)
      return {
        success: true,
        status: "Item removed from cart."
      }
    }
  }

  // Clear the cart
  const clearCart: () => CartEditFnReturnType = () => {
    setCartState([])
    return {
      success: true,
      status: "Cart cleared."
    }
  }

  // Import a cart (i.e. starting off with a custom cart when editing an order)
  const importCart: (input: CartV2LineItem[]) => CartEditFnReturnType = (input) => {
    setCartState(input)
    return {
      success: true,
      status: "Cart imported."
    }
  }

  // Save cart to localstorage
  // ONLY used when storing cart data is 100% necessary, aka when entering the raffle and need to preserve cart data on refresh
  const saveCart: () => CartEditFnReturnType = () => {
    setStoredCartV2(cartState)
    return {
      success: true,
      status: "Cart saved"
    }
  }

  // Save cart to localstorage
  // ONLY used when storing cart data is 100% necessary, aka when entering the raffle and need to preserve cart data on refresh
  const deleteSavedCart: () => CartEditFnReturnType = () => {
    clearStoredCartV2()
    return {
      success: true,
      status: "Saved cart removed"
    }
  }

  // Load saved cart from localstorage
  const loadSavedCart: () => CartEditFnReturnType = () => {
    const loadedCart = getStoredCartV2()
    if (loadedCart) {
      setCartState(loadedCart)
      return {
        success: true,
        status: "Saved cart removed"
      }
    } else {
      return {
        success: false,
        status: "Saved cart does not exist"
      }
    }
  }

  // Change option choices for an item
  const setChoiceForItem: (inputItem: CartV2LineItem, option: CartV2SelectedOption) => CartEditFnReturnType = (inputItem, option) => {
    // Shallow copy the cart for editing
    console.log("Cart state edit: Removing an item")
    let newCart = cartState.map(a => ({ ...a }))

    // Find the item index for the product to remove
    const itemIndex = findItemInCart(newCart, inputItem)

    // If item does not exist in cart, fail
    if (!itemIndex) {
      return {
        success: false,
        status: "Item does not exist in cart to be modified."
      }
    }

    // Find the option to modify
    const optionIndex = newCart[itemIndex.index].options.findIndex(item => item.option_id === option.option_id)
    if (optionIndex < 0) {
      return {
        success: false,
        status: "Option does not exist"
      }
    }
    
    // LONG-TERM: Remove added cost SKU from cart item entries and build a LUT instead

    /// If the new quantity is still positive, adjust qty and return
    newCart[itemIndex.index].options[optionIndex] = {
      option_id: option.option_id,
      choice_id: option.choice_id,
      upgrade_variant_id: option.upgrade_variant_id
    }
    setCartState(newCart)
    return {
      success: true,
      status: "Option adjusted."
    }

  }


  // Calculate quantity of items in cart
  const totalItems = getTotalItemQty(cartState)

  // Calculate cart total price
  const totalCartPrice = getTotalCartPrice(cartState, globalContext.selectedCollection?.upgradeVariants!, globalContext.selectedCollection?.catalog || [])
  if (totalCartPrice < 0) {
    setErrorMsg("An upgrade variant ID was referenced which is not included in the upgrade variants table")
  }

  // Find any products in cart that exceed available quantities
  const cartItemsExceedingAvailableStock = findCartItemsExceedingStock(cartState, globalContext.selectedCollection?.catalog!)
  if (errorMsg) {
    setErrorMsg(cartItemsExceedingAvailableStock.error?.message || "")
  }


  // Return proper context value
  const loadedContextValue = {
    cart: cartState,

    totalItems: totalItems,
    totalPrice: totalCartPrice,

    isLoading: false,
    isError: false,
    statusMsg: "Ready",

    addToCart: addToCart,
    setQuantityForItem: setQuantityForItem,
    setChoiceForItem: setChoiceForItem,
    clearCart: clearCart,
    saveCart: saveCart,
    deleteSavedCart: deleteSavedCart,
    loadSavedCart: loadSavedCart,
    importCart: importCart,

    cartItemsExceedingAvailableStock: cartItemsExceedingAvailableStock.items,
  }

  return (
    <CartManagerV2Context.Provider value={loadedContextValue}>
      {children}
    </CartManagerV2Context.Provider>
  )

}
