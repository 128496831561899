import React from "react"
import {
  Box,
  Container,
  Stack,
  Text,
  Wrap,
} from "@chakra-ui/react"
import { useCartManagerV2 } from "../DataManagement/CartManagerV2"
import { useGlobalContext } from "../DataManagement/GlobalContextManager"

export const FloatingCartBar: React.FC<{
  buttonArea: React.ReactNode,
  displayGbStatus: boolean,
}> = ({
  buttonArea,
  displayGbStatus,
}) => {

  const globalContext = useGlobalContext()
  const cartManagerV2 = useCartManagerV2()

    return (
      <Box bgColor="gray.300" py={4} textColor="black" position="sticky" bottom="0">
        <Container maxW="container.lg">
          <Stack direction={["column", "row"]} align="center">
            <Stack flex={1} direction={["row", "column"]} align={["center", "start"]} textAlign={["center", "start"]}>
              <Text fontSize="sm">Cart total - {cartManagerV2.totalItems.toFixed(0)} item{cartManagerV2.totalItems !== 1 && "s"}</Text>
              <Text fontSize="2xl" fontWeight="600">${cartManagerV2.totalPrice.toFixed(2)}</Text>
            </Stack>

            <Wrap direction={["row"]} align="center" justify="center">
              {displayGbStatus && globalContext.collectionTimeState === "PENDING_RAFFLE" &&
                <Text>Raffling will begin in {globalContext.selectedCollection?.raffleStartTime.diffNow("minutes").toFormat("mm:ss")}</Text>
              }
              {displayGbStatus && globalContext.collectionTimeState === "RAFFLING" &&
                <Text>Raffling has begun - join now!</Text>
              }
              {buttonArea}
            </Wrap>

          </Stack>

        </Container>
      </Box>
    )
  }