import React, { useState } from "react"
import {
  Text,
  Button,
  Stack,
  Tooltip,
  Wrap,
  Spinner,
} from "@chakra-ui/react"
import { useGlobalContext } from "../DataManagement/GlobalContextManager"
import { Navigate, useNavigate } from "react-router-dom"
import { BasePageTemplate } from "./BasePageTemplate"
import { CartV2LineItem, CartV2SelectedOption, ErrorTypes } from "../DataManagement/Types"
import { ErrorScreen } from "../CommonComponents/ErrorScreen"
import { useCartManagerV2 } from "../DataManagement/CartManagerV2"
import { InCartEntryV2 } from "./InCartEntryV2"
import { FloatingCartBar } from "./FloatingCartBar"
import { findMatchingCatalogItemByVariantId } from "../DataManagement/CartManagerV2Utils"


export const CartV2CheckoutView: React.FC<{}> = () => {

  // Tap into contexts and hooks
  const globalContext = useGlobalContext();
  const cartManagerV2 = useCartManagerV2();
  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState<string | null>(null)


  const everythingLoaded = !globalContext.isLoading && !cartManagerV2.isLoading


  const oosProductsNames: string[] = cartManagerV2.cartItemsExceedingAvailableStock.map(item => {
    const catalogItem = findMatchingCatalogItemByVariantId(item.variant_id, globalContext.selectedCollection?.catalog || [])
    if (!catalogItem) {
      // This shouldn't happen, but worst case skip over it (filter it out below)
      return ""
    }
    else if (catalogItem?.productType === "UNSPLIT_VARIANTS") {
      const matchingVariant = catalogItem.unsplitVariants.find(item => item.variantId === item.variantId)
      return `${catalogItem.productTitle}${matchingVariant ? ` - ${matchingVariant.displayName}` : ""}`
    }
    else {
      return catalogItem.productTitle
    }
  }).filter(item => item !== "")


  // Functions to adjust qty or remove an item
  const removeFromCart = (item: CartV2LineItem) => {
    const editResponse = cartManagerV2.setQuantityForItem(item, 0)
    if (!editResponse.success) {
      setErrorMsg(errorMsg)
    } else {
      setErrorMsg(null)
    }
  }

  const setCartQuantity = (item: CartV2LineItem, quantity: number) => {
    const editResponse = cartManagerV2.setQuantityForItem(item, quantity)
    if (!editResponse.success) {
      setErrorMsg(errorMsg)
    } else {
      setErrorMsg(null)
    }
  }

  const setOptions: (item: CartV2LineItem, option: CartV2SelectedOption) => void = (item, option) => {
    const ret = cartManagerV2.setChoiceForItem(item, option)
    console.log(ret)
  }

  const joinSaleButtonText = globalContext.inStockModeSwitch ? "Checkout" : "Join Sale"


  if (globalContext.isError) {
    // Error screen
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Review Cart"}>
        <ErrorScreen error={({ type: ErrorTypes.GlobalContextError, message: "" })} />
      </BasePageTemplate>
    )
  } else if (cartManagerV2.isError) {
    // Error screen
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Review Cart"}>
        <ErrorScreen error={({ type: ErrorTypes.CartManagerError, message: cartManagerV2.statusMsg || "" })} />
      </BasePageTemplate>
    )
  } else if (errorMsg) {
    // Local errors
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Review Cart"}>
        <ErrorScreen error={({ type: ErrorTypes.CartEditError, message: cartManagerV2.statusMsg || "" })} />
      </BasePageTemplate>
    )
  } else if (globalContext.subscriptionId) {
    // Raffle sub ID exists = Move to raffling screen
    return <Navigate to="/raffle" />
  } else if (globalContext.selectedCollectionHandle === "") {
    // No collection specified to load
    return <Navigate to="/" />
  } else if (!globalContext.isLoading && !globalContext.selectedCollection) {
    // global context has loaded, yet no collection has been selected
    // Deadlock, must nav out
    return <Navigate to="/" />
  } else if (!everythingLoaded || !globalContext.selectedCollection) {
    // Things still loading
    // Display a loading screen
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Review Cart"}>
        <Spinner />
        <Text mt={2} mb={8}>Loading...</Text>
      </BasePageTemplate>
    )
  } else if (globalContext.collectionTimeState === "CLOSED") {
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Review Cart"}>
        <Text>The sale has ended.</Text>
        <Button onClick={() => navigate("/")} colorScheme="whiteAlpha">Main Menu</Button>
      </BasePageTemplate>
    )
  } else if (globalContext.collectionTimeState === "BEFORE_LAUNCH") {
    // Illegal time
    return <Navigate to="/" />
  } else if (globalContext.selectedCollection.totalHypemainQty <= 0) {
    // Sold out
    return (
      <BasePageTemplate maxW="container.lg" headerText={"Review Cart"}>
        <Text>The product has sold out.</Text>
        <Button onClick={() => navigate("/")} colorScheme="whiteAlpha">Main Menu</Button>
      </BasePageTemplate>
    )
  } else if (cartManagerV2.cart.length <= 0) {
    // Cart empty
    return (
      <>
        <BasePageTemplate maxW="container.lg" headerText={"Review Cart"}>
          <Text>Your cart is empty.</Text>
          <Button onClick={() => navigate("/cart")} mt={2} colorScheme="whiteAlpha">Back to Shop</Button>
        </BasePageTemplate>

        {/* Floating cart area */}
        <FloatingCartBar
          buttonArea={
            <Wrap direction={["row"]} align="center" justify="center">
              <Button onClick={() => navigate("/cart")}>Back to Shop</Button>
              <Button colorScheme="ck" disabled>{joinSaleButtonText}</Button>
            </Wrap>
          }
          displayGbStatus={!globalContext.inStockModeSwitch}
        />
      </>
    )
  } else {
    // Normal render
    return (
      <>
        <BasePageTemplate maxW="container.lg" headerText={"Review Cart"}>
          <Stack direction="column" spacing="24px" justify="center" pb={16}>

            {cartManagerV2.cart.map((item, index) => (
              <InCartEntryV2
                key={`incartitem-${item.variant_id}-${index.toString()}`}
                cartItem={item}
                setOptions={setOptions}
                removeFromCart={removeFromCart}
                setCartQuantity={setCartQuantity}
                originalQuantity={null}
              />
            ))}
          </Stack>

          {/* Spacer so there's a gap under the last item */}
        </BasePageTemplate>

        {/* Floating cart area */}
        <FloatingCartBar
          buttonArea={
            <Wrap direction={["row"]} align="center" justify="center">
              <Button onClick={() => navigate("/cart")}>Back to Shop</Button>
              {cartManagerV2.cartItemsExceedingAvailableStock.length > 0 ?
                <Tooltip label={`The following products are no longer available in the quantities desired: ${oosProductsNames.join(", ")}`} shouldWrapChildren >
                  <Button colorScheme="ck" disabled>{joinSaleButtonText}</Button>
                </Tooltip>
                :
                <Button colorScheme="ck" onClick={() => navigate("/subscribe")}>{joinSaleButtonText}</Button>
              }
            </Wrap>
          }
          displayGbStatus={!globalContext.inStockModeSwitch}
        />
      </>
    )
  }
}
